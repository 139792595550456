import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, act } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import * as UserActions from './user.actions';
import * as OrganizationsActions from '../../../../organizations/src/lib/+state/organizations/organizations.actions';
import {
  UserService,
  OrganizationsService,
  DeletePictureService,
  CookiesService,
} from '@razroo-zeta/data-services';
import { first, map, takeUntil } from 'rxjs/operators';
import { UserEntity } from './user.models';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { CreateUserDialogComponent } from 'libs/ui/common/src/lib/create-user-dialog/create-user-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from '@razroo-zeta/common-services';

@Injectable()
export class UserEffects {
  activeOrgId: any = '';

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUser),
      fetch({
        run: (action) => {
          return this.organizationsService
            .getUserAndOrganizationUser()
            .pipe(
              map((user: any) =>
                UserActions.loadUserSuccess({ user: { ...user } })
              )
            );
        },
        onError: (action, error) => {
          if (error) {
            const createUserDialog = this.dialog.open(
              CreateUserDialogComponent
            );
            createUserDialog.afterClosed().subscribe((result) => {
              this.userService
                .createUserProfile(result.firstName, result.lastName)
                .pipe(first())
                .subscribe((createUserProfileRes: any) => {
                  if (
                    !this.localStorageService
                      .getItem('redirect_url')
                      ?.includes('/accept-invitation?token=')
                  ) {
                    let userId = createUserProfileRes['userId'];
                    this.organizationsService
                      .createOrganization(userId, true)
                      .pipe(first())
                      .subscribe((createOrgRes: any) => {
                        this.userService
                          .updateActiveOrgId(createOrgRes['orgId'], userId)
                          .pipe(first())
                          .subscribe((res3: any) => {
                            console.log('dispath in if error is called');
                            this.store.dispatch(UserActions.loadUser({}));
                          });
                      });
                  } else {
                    console.log('dispath in else error is called');
                    this.store.dispatch(UserActions.loadUser({}));
                  }
                });
            });
          } else {
            console.log(error);
          }
        },
      })
    )
  );

  loadUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserSuccess),
      fetch({
        run: (action) => {
          // if (!action.user.userId) {
          //   this.userService.createUserProfile().subscribe();
          // }
          if (
            this.localStorageService
              .getItem('redirect_url')
              ?.includes('/accept-invitation?token=')
          ) {
            this.localStorageService.removeItem('redirect_url');
          }
          if (!this.cookiesService.getItem('visited')) {
            this.cookiesService.setItem(
              'visited',
              'true',
              '2030-01-01T00:00:00.000Z'
            );
          }
          return UserActions.loadUserProfile({ userId: action.user.userId });
        },
      })
    )
  );

  loadUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserProfile),
      fetch({
        run: (action) => {
          return this.organizationsService.getUserAndOrganizationUser().pipe(
            map((user: any) =>
              UserActions.loadUserProfileSuccess({
                update: { id: action.userId, changes: user },
                userId: action.userId,
              })
            )
          );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return UserActions.loadUserProfileFailure({ error });
        },
      })
    )
  );

  loadUserProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserProfileSuccess),
      fetch({
        run: (action) => {
          if (!this.cookiesService.getItem('visited')) {
            this.cookiesService.setItem(
              'visited',
              'true',
              '2030-01-01T00:00:00.000Z'
            );
          }
          if (this.cookiesService.getItem('loggingIn')) {
            this.cookiesService.removeItem('loggingIn');
          }
          this.activeOrgId = action.update.changes.activeOrgId;
        },
      })
    )
  );

  loadUserProfileFromScratch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserProfileFromScratch),
      fetch({
        run: (action) => {
          return this.organizationsService.getUserAndOrganizationUser().pipe(
            map((user: any) =>
              UserActions.loadUserProfileFromScratchSuccess({
                userProfile: user,
                userId: action.userId,
              })
            )
          );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return UserActions.loadUserProfileFailure({ error });
        },
      })
    )
  );

  updateOrgUserActiveStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateOrgUserActiveStep),
      fetch({
        run: (action) => {
          return this.organizationsService
            .setActiveStep(action.orgId, action.userId, action.activeStep)
            .pipe(
              map((user: any) => {
                return UserActions.updateOrgUserActiveStepSuccess({ user });
              })
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return UserActions.updateOrgUserActiveStepFailure({ error });
        },
      })
    )
  );

  loadUserProfileFromScratchSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserProfileFromScratchSuccess),
      fetch({
        run: (action) => {
          if (!this.cookiesService.getItem('visited')) {
            this.cookiesService.setItem(
              'visited',
              'true',
              '2030-01-01T00:00:00.000Z'
            );
          }
          if (this.cookiesService.getItem('loggingIn')) {
            this.cookiesService.removeItem('loggingIn');
          }
          this.activeOrgId = action.userProfile.activeOrgId;
          return OrganizationsActions.loadOrganization({
            orgId: this.activeOrgId,
          });
        },
      })
    )
  );

  loadUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserRoles),
      fetch({
        run: (action: any) => {
          return this.userService.getUserRoles(action.user.userId).pipe(
            map((roles: any) =>
              UserActions.loadUserRolesSuccess({
                user: action.user as UserEntity,
                roles: roles,
              })
            )
          );
        },
      })
    )
  );

  loadUserOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserOrganizations),
      fetch({
        run: (action) => {
          return this.userService.getUserOrganizations(action.userId).pipe(
            map((user: any) =>
              UserActions.loadUserOrganizationsSuccess({
                update: { id: action.userId, changes: user },
              })
            )
          );
        },
        onError: (error) => {
          console.error('Error', error);
          return UserActions.loadUserOrganizationsFailure({ error });
        },
      })
    )
  );

  deleteProfilePic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteProfilePic),
      fetch({
        run: (action) => {
          return this.deletePictureService.deletePicture(action.userId).pipe(
            map(() => {
              this.snackBar.open('Profile picture has been deleted', '', {
                duration: 3000,
              });
              return UserActions.deleteProfilePicSuccess({
                userId: action.userId,
              });
            })
          );
        },
        onError: (error) => {
          console.error('Error', error);
          return UserActions.deleteProfilePicFailure({ error });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private organizationsService: OrganizationsService,
    private deletePictureService: DeletePictureService,
    private store: Store,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private localStorageService: LocalStorageService,
    private cookiesService: CookiesService
  ) {}
}
