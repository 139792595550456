export const TicketFragment = `
    fragment ticketFields on Ticket {
        boardId
        columnId
        columns {
          title
          columnId
        }
        content
        dateCreated
        description
        condensations {
          text
          condensation  
        }
        estimatedStoryPoints
        estimatedStoryPointsReason {
            complexity {reason, points}
            effort {reason, points}
            risk {reason, points}
            dependencies {reason, points}
            knowledgeAndSkillRequirements {reason, points}
            repeatability {reason, points}
            sizeAndScope {reason, points}
            uncertainty {reason, points}
            consensus {reason, points}
            historicalData {reason, points}
        }
        shortDescription
        labels
        orgId
        codeSnippet
        ticketNumber
        jiraTicketKey
        epic
        prompt
        title
        type
        userId
        additionalNotes
        email
        firstName
        lastName
        workspaceId
        acceptanceCriteria
        graphqlSchema
        mediaAttachments {
          downloadUrl
          fileName
          fileType
        }
        parentTicket {
          ticketNumber
          workspaceId
          orgId
          title
          type
        }
        parentTicketNumber
        childTickets { 
          ticketNumber
          title
          description
          epic
          labels
          orgId
          workspaceId
          boardId
          assignee {
            userId
            email
            firstName
            lastName
            picture
          }
          type
          estimatedStoryPoints
          columnId
        }
        userJourney {
            discovery
            learning
            consideration
            decision
            evaluation
        }
        businessValue {
            strategicAlignment
            customerImpact
            revenueGeneration
            riskManagement
            efficiencyAndProductivity
            competitiveAdvantage
        }
        assignee {
            userId
            email
            firstName
            lastName
            picture
        }
        sprintBoard {
            boardId
            boardName
            description
            startDate
            endDate
        }
        zeplinImage {
            downloadUrl
            fileName
        }
        razrooError {
          name
          message
        }
        watchers {
            userId
            email
            firstName
            lastName
            picture
        }
        activeChildTicketsCounter
        inProgressChildTicketsCounter
        childTicketsCounter
        startDate
        endDate
    }
`;

export const TicketUpdateFragment = `
  fragment ticketUpdateFields on TicketUpdate {
    userId
    orgId
    title
    description
    workspaceId
    ticketNumber
    condensations {
      text
      condensation  
    }
    labels
    type
    epic
    estimatedStoryPoints
  }
`;

export const TicketWorkspaceFragment = `
    fragment ticketWorkspaceFields on TicketWorkspace {
        dateCreated
        description
        businessDescription
        targetAudience
        jiraProject
        lastUpdated
        manualSprintCompletion
        orgId
        ticketCounter
        activeTickets
        userId
        workspaceId
        slackChannelId
        slackChannelName
        timezone
        trackName
        workspaceName
        activeBoard
        graphqlSchemaUploaded
        users {
            email
            name
            userId
            picture
        }
    }
`;

export const TicketWorkspaceBoardsFragment = `
  fragment ticketWorkspaceBoardFields on TicketWorkspaceBoard {
    boardId
    boardName
    workspaceId
    description
    columns {
      columnId
      title
      tickets {
        ...ticketFields
      }
    }
    startDate
    endDate
    dateCreated
    lastUpdated
    userId
    orgId
    board
    completed
  }
  ${TicketFragment}
`;

export const TicketWorkspaceBoardFragment = `
  fragment ticketWorkspaceIndividualBoardFields on TicketWorkspaceBoard {
    boardId
    boardName
    workspaceId
    description
    columns {
      columnId
      title
      tickets {
        ...ticketFields
      }
    }
    startDate
    endDate
    dateCreated
    lastUpdated
    tickets {
      ...ticketFields
    }
    userId
    orgId
    board
    completed
  }
  ${TicketFragment}
`;


