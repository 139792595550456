import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import * as TemplatesActions from './templates.actions';
import * as TemplatesFeature from './templates.reducer';
import * as TemplatesSelectors from './templates.selectors';
import { loadActiveState } from '@razroo-zeta/data-access/organizations';
import { OrganizationUser, Step, StepUpdateParams, Template } from '@razroo-zeta/data-models';

@Injectable()
export class TemplatesFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(TemplatesSelectors.getTemplatesLoaded));
  relevantQuestionsAiLoaded$ = this.store.pipe(select(TemplatesSelectors.getRelevantQuestionsAiLoaded));
  instructionalContentAiLoaded$ = this.store.pipe(select(TemplatesSelectors.getInstructionalContentAiLoaded));
  allTemplates$ = this.store.pipe(select(TemplatesSelectors.getAllTemplates));
  selectedTemplate$ = this.store.pipe(select(TemplatesSelectors.getSelected));
  relevantQuestions$ = this.store.pipe(select(TemplatesSelectors.getRelevantQuestions));
  instructionalContent$ = this.store.pipe(select(TemplatesSelectors.getSelectedInstructionalContent));
  commandText$ = this.store.pipe(select(TemplatesSelectors.getCommandText));
  position$ = this.store.pipe(select(TemplatesSelectors.getPosition));
  templateId$ = this.store.pipe(select(TemplatesSelectors.getTemplateId));
  stepper$ = this.store.pipe(select(TemplatesSelectors.getStepper));
  selectedStepper$ = this.store.pipe(select(TemplatesSelectors.getSelectedStepper));
  aggregate$ = this.store.pipe(select(TemplatesSelectors.getAggregate));
  updates$ = this.store.pipe(select(TemplatesSelectors.getTemplateUpdates));
  getTemplatesRecipeIdHoveredOver$ = this.store.pipe(select(TemplatesSelectors.getTemplatesRecipeIdHoveredOver));

  constructor(private store: Store<TemplatesFeature.TemplatesPartialState>) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(TemplatesActions.init());
  }

  loadTemplate(orgId: string, pathId: string, recipeId: string, templateID?: string, networkOnly?: boolean, published?: boolean, displayDocumentationHtml?: boolean) {
    this.store.dispatch(TemplatesActions.loadTemplate({ orgId, pathId, recipeId, templateID, networkOnly, published, displayDocumentationHtml}));
  }
  loadStepInSearch(userOrgId: string, templateOrgId: string, pathId: string, recipeId: string, stepId: string, displayDocumentationHtml?: boolean) {
    this.store.dispatch(TemplatesActions.loadStepInSearch({userOrgId, templateOrgId, pathId, recipeId, stepId, displayDocumentationHtml}));
  }
  
  loadAiTemplate(orgId: string, pathId: string, recipeId: string, templateID?: string, networkOnly?: boolean, published?: boolean) {
    this.store.dispatch(TemplatesActions.loadAiTemplate({ orgId, pathId, recipeId, templateID, networkOnly, published}));
  }

  loadPathBatchTemplate(orgId: string, pathId: string, batchId: string, recipeId: string, stepId: string, openGenerateDialog?: boolean, executable?: boolean) {
    this.store.dispatch(TemplatesActions.loadPathBatchTemplate({ orgId, pathId, batchId, recipeId, stepId, openGenerateDialog, executable}));
  }

  loadStepper(orgId: string, pathId: string, recipeId?: string, stepId?: string, amount?: number) {
    this.store.dispatch(TemplatesActions.loadStepper({ orgId, pathId, recipeId, stepId, amount }));
  }

  toggleAggregate() {
    this.store.dispatch(TemplatesActions.toggleAggregate())
  }

  loadActiveState(userOrgId: string | undefined, templateOrgId: string, pathId: string, recipeId: string, stepId: string, user: OrganizationUser) {
    this.store.dispatch(loadActiveState({ userOrgId, templateOrgId, pathId, recipeId, stepId, user }));
  }

  updateStep(orgId: string, pathId: string, recipeId: string, id: string, stepUpdateParams: StepUpdateParams) {
    this.store.dispatch(TemplatesActions.updateStep({orgId, pathId, recipeId, id, stepUpdateParams}))
  }

  updateStepSuccess(template: any) {
    this.store.dispatch(TemplatesActions.loadTemplateSuccess({template: template}))
  }

  createStep(template: Template, userId: string, stepName: string, stepType: string, description: string) {
    this.store.dispatch(TemplatesActions.createStep({template, userId, stepName, stepType, description}))
  }

  likeTemplate(userId: string, templateOrgId: string, pathId: string, recipeId: string, stepId: string){
    this.store.dispatch(TemplatesActions.likeTemplate({userId, templateOrgId, pathId, recipeId, stepId}))
  }

  downvoteTemplate(userId: string, templateOrgId: string, pathId: string, recipeId: string, stepId: string){
    this.store.dispatch(TemplatesActions.downvoteTemplate({userId, templateOrgId, pathId, recipeId, stepId}))
  }

  resetStore() {
    this.store.dispatch(TemplatesActions.resetStore());
  }

  setCommandText(commandText?: string) {
    this.store.dispatch(TemplatesActions.setCommandText({commandText}));
  }

  stepHoveredOver(step: Step) {
    this.store.dispatch(TemplatesActions.stepHoveredOver({step}));
  }

  stepUnhoveredOver(template: Template) {
    this.store.dispatch(TemplatesActions.stepUnhoveredOver({template}));
  }

  addRelevantQuestionsViaAiToStep(orgId: string, pathId: string, recipeId: string, stepId: string, scaffoldName: string, featureName: string) {
    this.store.dispatch(TemplatesActions.addRelevantQuestionsViaAiToStep({orgId, pathId, recipeId, stepId, scaffoldName, featureName}));
  }

  
  addDocumentationViaAiToStep(pathOrgId: string, pathId: string, recipeId: string, stepId: string, featureName: string, featureType: string, fileTree?: string[]) {
    this.store.dispatch(TemplatesActions.addDocumentationViaAiToStep({pathOrgId, pathId, recipeId, stepId, featureName, featureType, fileTree}));
  }
}
