import { SendGithubReposDataSub, SendGithubBranchesDataSub } from '@razroo-zeta/data-graphql';
import { Injectable } from '@angular/core';
import { AUTH_TYPE, AWSAppSyncClient } from 'aws-appsync';
import { environment } from '../../../../../../apps/zeta-frontend/src/environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
const type = AUTH_TYPE.OPENID_CONNECT;

@Injectable({
  providedIn: 'root'
})
export class GithubSubscriptionService {
  constructor(private authService: AuthService) { }

  client(accessToken: string) {
    return new AWSAppSyncClient({
      url: environment.graphQLURI,
      region: 'us-east-1',
      auth: {
        type,
        jwtToken: accessToken,
      },
      disableOffline: true
    });
  }

  sendGithubReposDataSub(userId: string) {
    return new Observable(observer => {
      this.authService.getAccessTokenPromise().then(accessToken => {
        this.client(accessToken)
          .hydrated()
          .then(client => client.subscribe({
          query: SendGithubReposDataSub,
          variables: {
            userId
          } 
        }).subscribe({
          next: res => {
            observer.next(res?.data?.sendGithubReposDataSub);
          },
          error: err => {
            observer.error(err);
          },
          complete: () => {
            observer.complete();
          }
        })
          )
      });
    });
  }

  sendGithubBranchesDataSub(userId: string) {
    return new Observable(observer => {
      this.authService.getAccessTokenPromise().then(accessToken => {
        this.client(accessToken)
          .hydrated()
          .then(client => client.subscribe({
          query: SendGithubBranchesDataSub,
          variables: {
            userId
          } 
        }).subscribe({
          next: res => {
            console.log('res');
            console.log(res);
            observer.next(res?.data?.sendGithubBranchesDataSub);
          },
          error: err => {
            observer.error(err);
          },
          complete: () => {
            observer.complete();
          }
        })
          )
      });
    });
  }
}