import { createAction, props } from '@ngrx/store';
import { ActiveStepInput, Step, Stepper, StepUpdateParams, Template } from '@razroo-zeta/data-models';
import { TemplatesEntity } from './templates.models';

export const init = createAction('[Templates Page] Init');

export const loadTemplate = createAction(
  '[Template] Load',
  props<{ orgId: string, pathId: string, recipeId: string, templateID?: string, networkOnly?: boolean, published?: boolean, displayDocumentationHtml?: boolean }>()
);

export const loadTemplateSuccess = createAction(
  '[Template/API] Load Template Success',
  props<{ template: TemplatesEntity}>()
);
export const loadStepInSearch = createAction(
  '[Template] Load Step In Search',
  props<{ userOrgId: string, templateOrgId: string, pathId: string, recipeId: string, stepId: string, displayDocumentationHtml?: boolean }>()
);

export const loadAiTemplate = createAction(
  '[Template] Load AI Template',
  props<{orgId: string, pathId: string, recipeId: string, templateID?: string, networkOnly?: boolean, published?: boolean}>()
);

export const loadTemplatePolling = createAction(
  '[Template] Load Template Polling',
  props<{ template: Template }>()
);

export const loadPathBatchTemplate = createAction(
  '[Template] Load Path Batch Template',
  props<{ orgId: string, pathId: string, batchId: string, recipeId: string, stepId: string, openGenerateDialog?: boolean, executable?: boolean }>()
);

export const loadPathBatchTemplateSuccess = createAction(
  '[Template] Load Path Batch Template Success',
  props<{ template: TemplatesEntity, openGenerateDialog?: boolean}>()
);

export const loadStepper = createAction(
  '[Template] Load Stepper',
  props<{ orgId: string, pathId: string, recipeId?: string, stepId?: string, amount?: number}>()
);

export const loadStepperSuccess = createAction(
  '[Template/API] Load Stepper Success',
  props<{ stepper: Stepper[], amount: number, pathId?: string, orgId?: string}>()
);

export const loadStepperSupplementSuccess = createAction(
  '[Template] Load Stepper Supplement Success',
  props<{ stepper: Stepper[], amount: number}>()
);

export const loadStepperError = createAction(
  '[Template/API] Load Stepper Error',
  props<{ error: any}>()
);

export const updateStep = createAction(
  '[Template] Update Step',
  props<{ orgId: string, pathId: string, recipeId: string, id: string, stepUpdateParams: StepUpdateParams}>()
);

export const updateStepSuccess = createAction(
  '[Template] Update Step Success',
  props<{ template: Template}>()
);

export const updateStepFailure = createAction(
  '[Template] Update Step Failure',
  props<{ error: any}>()
);

export const updateActivePathOrgId = createAction(
  '[Template] Update Active Path Org Id',
  props<{ activePathOrgId: string}>()
);

export const toggleAggregate = createAction(
  '[Template] Toggle Aggregate',
);

export const updateActiveStep = createAction(
  '[Template] Update Active Step',
  props<{ activeStepInput: ActiveStepInput }>()
);

export const loadActiveState = createAction(
  '[Template] Load Active State',
  props<{ templateID: string }>()
);

export const loadActiveStateSuccess = createAction(
  '[Template/API] Load Active State Success',
  props<{ template: TemplatesEntity }>()
);

export const loadActiveStateFailure = createAction(
  '[Template/API] Load Active State Failure',
  props<{ error: any }>()
);

export const loadTemplateFailure = createAction(
  '[Template/API] Load Template Failure',
  props<{ error: any }>()
);

export const loadTemplatesSuccess = createAction(
  '[Templates/API] Load Templates Success',
  props<{ templates: TemplatesEntity[] }>()
);

export const loadTemplatesFailure = createAction(
  '[Templates/API] Load Templates Failure',
  props<{ error: any }>()
);

export const createStep = createAction(
  '[Template] Create Step',
  props<{ userId: string, template: Template,
    stepName: string, stepType: string, description: string }>()
);

export const createStepSuccess = createAction(
  '[Template] Create Step Success',
  props<{ template: Template, stepper: any }>()
);

export const createStepFailure = createAction(
  '[Template] Create Step Failure',
  props<{ error: any }>()
);

export const resetStore = createAction(
  '[Template] Reset Store'
);

export const likeTemplate = createAction(
  '[Template] Like Template',
  props<{ userId: string, templateOrgId: string, pathId: string, recipeId: string, stepId: string}>()
);
export const likeTemplateSuccess = createAction(
  '[Template] Like Template Success',
  props<{ template: Template }>()
);
export const likeTemplateFailure = createAction(
  '[Template] Like Template Failure',
  props<{ error: any }>()
);

export const downvoteTemplate = createAction(
  '[Template] Downvote Template',
  props<{ userId: string, templateOrgId: string, pathId: string, recipeId: string, stepId: string}>()
);

export const downvoteTemplateSuccess = createAction(
  '[Template] Downvote Template Success',
  props<{ template: Template }>()
);
export const downvoteTemplateFailure = createAction(
  '[Template] Downvote Template Failure',
  props<{ error: any }>()
);

export const stepHoveredOver = createAction(
  '[Template] Step Hovered Over',
  props<{ step: Step}>()
);

export const stepUnhoveredOver = createAction(
  '[Template] Step Unhovered Over',
  props<{ template: Template}>()
);

export const addRelevantQuestionsViaAiToStep = createAction(
  '[Template] Add Relevant Questions Via Ai To Step',
  props<{ orgId: string, pathId: string, recipeId: string, stepId: string, scaffoldName: string, featureName: string }>()
);

export const addRelevantQuestionsViaAiToStepSuccess = createAction(
  '[Template] Add Relevant Questions Via Ai To Step Success',
  props<{ template: TemplatesEntity }>()
);

export const addDocumentationViaAiToStep = createAction(
  '[Template] Add Documentation Via Ai To Step',
  props<{ pathOrgId: string, pathId: string, recipeId: string, stepId: string, featureName: string, featureType: string, fileTree?: string[] }>()
);
  
export const addDocumentationViaAiToStepSuccess = createAction(
  '[Template] Add Documentation Via Ai To Step Success',
  props<{ stepId: string, markdown: string }>()
);

export const setCommandText = createAction(
  '[Template] Set Command Text',
  props<{ commandText?: string}>()
);

// action ss
