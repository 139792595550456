import { CookiesService } from './../../../../libs/data-services/src/lib/cookies/cookies.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { environment } from 'apps/zeta-frontend/src/environments/environment';
import { Injectable } from "@angular/core";
import { AuthHttpInterceptor } from "@auth0/auth0-angular";
import { Observable, mergeMap, take } from "rxjs";
import { AuthService } from '@razroo-zeta/data-services'

@Injectable()
export class RazrooAuthOrNoAuthHttpInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private authHttpInterceptor: AuthHttpInterceptor,
    private cookiesService: CookiesService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.isAuthenticated$.pipe(
      take(1),
      mergeMap((isAuthenticated: any) => {
        if (isAuthenticated) {
          return this.authHttpInterceptor.intercept(req, next);
        } else {
          let modifiedReq: any;
          if(req.url.includes(environment.graphQLURI)) {
            modifiedReq = req.clone({
              setHeaders: {
                'X-API-KEY': `${environment.awsApiKey}`
              }
            });
          } else {
            modifiedReq = req;
          }
          return next.handle(modifiedReq);
        }
      })
    );
  }
}