<ng-container *ngrxLet="currentUser$ as currentUser">
<ng-container *ngrxLet="selectedOrganization$ as currentOrganization">
<mat-toolbar class="mat-typography top-header">
  <div class="header-container">
    <button
      mat-icon-button
      (click)="emitSideNavToggle()"
      aria-label="Toggle Side Navigation"
    >
      <div class="Header__nav-content">
        <i class="fa-solid fa-bars Header__nav-icon"></i>
      </div>
    </button>
    <button
      mat-icon-button
      class="small-icon-button"
      matTooltip="Razroo Apps"
      [matMenuTriggerFor]="appMenu"
      aria-label="Toggle App Menu"
    >
      <i class="fa-solid fa-grid"></i>
    </button>
    <mat-menu #appMenu="matMenu" autoFocus="false">
      <div class="Header__header-title-container">
        <h4 class="Header__header-title">
          <i class="fa-solid fa-grid"></i>
          <span class="Header__header-title-text">Apps</span>
        </h4>
      </div>
      <hr>
      <a [routerLink]="['/finder']" mat-menu-item>
        <i class="fa-sharp-duotone fa-solid fa-code"></i>
        <span>Code</span>
      </a>
      <a [routerLink]="['/tickets']" mat-menu-item>
        <i class="fa-sharp-duotone fa-solid fa-tickets-simple"></i>
        <span>Projects</span>
      </a>
      <a href="https://www.starter-repos.com" target="_blank" mat-menu-item>
        <i class="fa-sharp-duotone fa-solid fa-code-branch"></i>
        <span>Starter Repos</span>
      </a>
    </mat-menu>
    <div class="Header__view-name-container">
      <div class="Header__logo"></div>

      <span class="Header__project-name">@if(router.url.startsWith('/tickets') || router.url.startsWith('/backlog')) {
        Projects
      } @else {
          Code
      }</span>
      
      <a
        target="_blank"
        href="https://www.blog.razroo.com/unified-intelligence-network/"
      >
        <div class="Home__logo-side-text">
            ^ Unified Intelligence Network
        </div>
      </a>
    </div>
    
    <div class="button-container">
      @if (!isAuthenticated()) {
      <button
        (click)="emitLogin()"
        data-cy="sign-in-button"
        mat-button
        color="primary"
      >
        Login
      </button>
      } @if (!isAuthenticated()) {
      <button
        (click)="emitSignUp()"
        mat-raised-button
        class="Header__sign-up"
        color="accent"
      >
        Get Started
      </button>
      } @if (isAuthenticated()) {
      <div class="right-links">
        <button mat-icon-button (click)="toggleDarkTheme()">
          <i class="fa-solid fa-eclipse-alt"></i>
        </button>
        <!-- took out trial days logic if not paid just display Upgrade now -->
        @if (!currentUser?.paid) {
          <button
            mat-button
            class="Header__upgrade-now"
            mat-button
            (click)="upgrade(currentUser!)"
          >
            Upgrade Plan <i class="fa-solid fa-person-to-portal"></i>
          </button>
        }
        <button
          mat-icon-button
          [matMenuTriggerFor]="belowMenu"
          #bellTrigger="matMenuTrigger"
        >
          <i
            class="fa-solid fa-bell"
            [matBadge]="(notifications | nonReadNotifications)?.length"
            matBadgeColor="warn"
            matBadgeSize="medium"
            [matBadgeHidden]="!(notifications | nonReadNotifications)?.length"
          ></i>
        </button>
        <mat-menu #belowMenu="matMenu">
          <div class="all-messages">
            @if (notifications?.length) {
            <div>
              @for (notification of (notifications | reverse); track notification?.timestamp) {
              <div (click)="$event.stopPropagation()">
                <razroo-zeta-notification-card
                  [notification]="notification"
                  (markReadClick)="markRead(notification, currentUser)"
                  (notificationClick)="notificationClick(notification)"
                  (deleteNotificationClick)="deleteNotification(notification, currentUser)"
                >
                </razroo-zeta-notification-card>
              </div>
              }
            </div>
            } @if (!notifications?.length) {
            <div>
              <div class="notif-item">No new notifications</div>
            </div>
            }
          </div>
        </mat-menu>
        <button
          mat-button
          [disabled]="!currentUser"
          (click)="loadUserOrgs(currentUser)"
          [matMenuTriggerFor]="menu"
        >
          <div class="avatar-container">
            @if(currentUser?.orgId !== currentUser?.userId ) { @if
            (currentUser?.orgPicture) {
            <img
              class="user-org-avatar"
              [src]="currentUser?.orgPicture"
              (error)="loadImageError($event)"
            />
            } @else {
            <span class="avatar-text">{{
              currentUser | orgInitials: currentUser
            }}</span>
            } } @else { @if (!(currentUser | ngrxPush)?.picture) {
            <i class="user-icon fa-solid fa-user-large"></i>
            } @else {
            <img
              class="user-avatar"
              [ngSrc]="(currentUser | ngrxPush)?.picture"
              fill
            />
            } }
          </div>
        </button>
        <mat-menu class="menu" #menu="matMenu">
            @if (!(organizations$ | ngrxPush)?.length) {
              <div mat-menu-item>
                <mat-spinner color="accent" diameter="35"></mat-spinner>
              </div>
            } @for (organization of (organizations$ | ngrxPush); track organization.id) {
              <button
                (click)="orgSelected(organization?.orgId, currentUser)"
                class="orgSelectionButton"
                [class]="
                  organization?.orgId === currentUser?.orgId
                    ? 'active-organization'
                    : ''
                "
                mat-menu-item
              >
                <div class="avatar-image">
                  @if (organization?.picture) {
                  <img
                    class="org-image"
                    [src]="organization?.picture"
                    (error)="loadImageError($event)"
                  />
                  } @else {
                  {{ organization | orgInitials: currentUser }}
                  }
                </div>
                <div class="orgNameCheck">
                  {{ organization | orgName: currentUser | titlecase }}
                  @if (organization?.orgId === currentUser?.orgId ||
                  !currentUser?.orgId && organization.orgId ===
                  currentUser?.userId) {
                  <i class="checkIcon fa-light fa-check"></i>
                  }
                </div>
              </button>
            }
          <hr />
          <button mat-menu-item [routerLink]="['/settings']">
            <i class="menuIcon fa-solid fa-cog"></i>Settings
          </button>
          <button
            mat-menu-item
            matTooltip="Personal Workspace Is Free Once Have A Organization Membership."
            matTooltipPosition="left"
            matTooltipClass="teams-tooltip"
            [routerLink]="['/settings/members']"
          >
            <i class="menuIcon fa-solid fa-plus-circle"></i>Create Organization
            or Invite
          </button>
          <button mat-menu-item (click)="emitLogout()">
            <i class="menuIcon fa-solid fa-sign-out"></i>Logout
          </button>
        </mat-menu>
      </div>
      }
    </div>
  </div>
</mat-toolbar>
</ng-container>
</ng-container>
