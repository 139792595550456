import { Component, Inject, OnInit, NgModule } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { take } from 'rxjs';
import { OrganizationUser } from '@razroo-zeta/data-models';
import { CreateSessionService, OrganizationsService, PricesService } from '@razroo-zeta/data-services';
import { WindowRefService } from '@razroo-zeta/common-services';

@Component({
  selector: 'razroo-zeta-unpaid-ticket-dialog',
  templateUrl: './unpaid-ticket-dialog.component.html',
  styleUrls: ['./unpaid-ticket-dialog.component.scss'],
})
export class UnpaidTicketDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      user: OrganizationUser
    },
    private dialogRef: MatDialogRef<UnpaidTicketDialogComponent>,
    private pricesService: PricesService,
    private createSessionService: CreateSessionService,
    private windowRefService: WindowRefService,
    private organizationsService: OrganizationsService
  ) {}

  ngOnInit(): void {  }
  
  upgrade($event: any, user: OrganizationUser) {
    this.pricesService
      .sendRequest()
      .pipe(take(1))
      .subscribe((res:any) => {
        // need orgnaization users to get quantity
        this.organizationsService.getOrganizationUsers(user.orgId).subscribe(organizationUsers => {
          const quantity = organizationUsers.length;
          // For now all prices are on teams pricing
          const price = res['data'].find((price:any) => price.lookup_key === 'teams_monthly').id;
          this.createSessionService
          .createStripeCheckoutSession(price, user?.orgId, quantity)
          .pipe(take(1))
          .subscribe((res:any) => (this.windowRefService.nativeWindow.location.href = res['url']));
        });
    });
    this.dialogRef.close($event);
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule
  ],
  declarations: [UnpaidTicketDialogComponent],
  exports: [UnpaidTicketDialogComponent],
})
export class UnpaidTicketComponentModule {}
