import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TICKETS_FEATURE_KEY,
  ticketsAdapter,
  State,
  titleTicketsAdapter
} from './tickets.reducer';

// Lookup the 'Tickets' feature state managed by NgRx
export const selectTicketsState = createFeatureSelector<State>(TICKETS_FEATURE_KEY);

const { selectAll, selectEntities } = ticketsAdapter.getSelectors();
const { selectAll: selectAllTitleTickets, selectEntities:  selectAllTitleTicketsEntities } = titleTicketsAdapter.getSelectors();

export const selectTicketsLoaded = createSelector(
  selectTicketsState,
  (state: State) => state.tickets.loaded
);

export const selectGeneratingAI = createSelector(
  selectTicketsState,
  (state: State) => state.tickets.generatingAI
);

export const selectGeneratingTicketAI = createSelector(
  selectTicketsState,
  (state: State) => state.tickets.generatingTicketAI
);

export const selectGeneratingTicketsAI = createSelector(
  selectTicketsState,
  (state: State) => state.tickets.generatingTicketsAI
);

export const selectTicketsError = createSelector(
  selectTicketsState,
  (state: State) => state.tickets.error
);

export const selectAllTickets = createSelector(
  selectTicketsState,
  (state: State) => selectAll(state.tickets)
);

export const selectAllEpics = createSelector(
  selectTicketsState,
  (state: State) => state.epics
);

export const selectTicketsEntities = createSelector(
  selectTicketsState,
  (state: State) => selectEntities(state.tickets)
);

export const selectTickets = createSelector(
  selectTicketsEntities,
  (entities: any) => entities ? Object.values(entities) : undefined
);

export const selectSelectedId = createSelector(
  selectTicketsState,
  (state: State) => state.tickets.selectedId
);

export const selectEntity = createSelector(
  selectTicketsEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
export const selectAllTicketHistory = createSelector(
  selectTicketsState,
  (state: State) => selectAll(state.ticketHistory)
);
export const selectHistorySelectedId = createSelector(
  selectTicketsState,
  (state: State) => state.ticketHistory.selectedId
);
export const selectHistoryLoaded = createSelector(
  selectTicketsState,
  (state: State) => state.ticketHistory.loaded
);
export const selectHistoryEdited = createSelector(
  selectTicketsState,
  (state: State) => state.ticketHistory.edited
);

export const selectColumnsLoaded = createSelector(
  selectTicketsState,
  (state: State) => state.columnsLoaded
);

export const ticketUpdating = createSelector(
  selectTicketsState,
  (state: State) => state.ticketUpdating
);

export const selectTicketUpdatingStatus = createSelector(
  selectTicketsState,
  (state: State) => state.ticketUpdatingStatus
);

export const selectAllColumns = createSelector(
  selectTicketsState,
  (state: State) => state.columns
);

export const selectBoardId = createSelector(
  selectTicketsState,
  (state: State) => state.boardId
);

export const selectEpicGenerating = createSelector(
  selectTicketsState,
  (state: State) => state.epicGenerating
);

export const selectGeneratingTitleTickets = createSelector(
  selectTicketsState,
  (state: State) => state.generatingTitleTickets
);

export const selectTitleTicketsEntities = createSelector(
  selectTicketsState,
  (state: State) => selectAllTitleTicketsEntities(state.titleTickets)
);

export const selectTitleTickets = createSelector(
  selectTitleTicketsEntities,
  (entities: any) => entities ? Object.values(entities) : undefined
);
