import { kebabCase } from 'lodash';
import { GoToNextStepDialogComponent } from './../../../../../../ui/common/src/lib/go-to-next-step-dialog/go-to-next-step-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import * as GenerateCodeActions from './generate-code.actions';
import { GenerateCodeService, TemplateService, VscodeService } from '@razroo-zeta/data-services';
import { concatMap, first, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { TemplatesFacade, UserFacade } from '@razroo-zeta/data-access';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalForageService } from '@razroo-zeta/common-services';
import { ActiveProject, GithubRepo, ProjectParams, RecommendationsStep, Template, VsCodeAuthInfo, VsCodeInstance } from '@razroo-zeta/data-models';
import { CodeGenerationHistoryFacade } from '@razroo-zeta/data-access/code-generation-history';
import { EMPTY, from, of } from 'rxjs';
import { PathFacade } from '@razroo-zeta/data-access/path';
import _ from 'lodash';
@Injectable()
export class GenerateCodeEffects {
  userId;
  orgId;
  loadGenerateCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GenerateCodeActions.loadGenerateCode),
      fetch({
        run: (action) => {
          return this.userFacade.currentUserID$.pipe(
            withLatestFrom(this.userFacade.currentOrgId$),
            first(),
            switchMap(([userId, orgId]) => {
              this.userId = userId;
              this.orgId = orgId;
              const generateCodeParameters = {
                ...action.generateCodeParameters,
                userId: userId,
                userOrgId: orgId,
              };
              return this.generateCodeService.generateVsCodeDownloadCode(
                generateCodeParameters as any
              );
            }),
            map((genCode: any) => {
              return GenerateCodeActions.loadGenerateCodeSuccess({
                template: action.template,
                stepper: action.stepper,
                generateCode: [
                  {
                    ...genCode,
                    id: action.generateCodeParameters.stepId,
                  },
                ],
                userId: this.userId,
                orgId: this.orgId,
                vsCodeInstances: action.vsCodeInstances,
                projectParams: action.projectParams
              });
            })
          );
        },

        onError: (action, error) => {
          console.error('Error', error);
          this._snackBar.open(error, '', { duration: 3000 });
          return GenerateCodeActions.loadGenerateCodeFailure({ error });
        },
      })
    )
  );

  runUnitTests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GenerateCodeActions.runUnitTests),
      fetch({
        run: (action) => {
          return this.vscodeService
            .getVsCodeAuthInfo(action.userId as string)
            .pipe(
              map((vsCodeInstances: VsCodeAuthInfo) => {
                const defaultVSCodeStarter =
                  this.generateCodeService.vsCodeDefaultStarter(
                    vsCodeInstances,
                    action.template
                  );
                if (!defaultVSCodeStarter) {
                  console.log('error');
                }
                const templateParameters = action.template.parameters
                  ? action.template.parameters
                  : [];
                const parameters =
                  this.generateCodeService.turnParametersIntoCodeGenerationSyntax(
                    templateParameters
                  );
                const generateCodeParameters =
                  this.generateCodeService.createGenerateCodeParametersForTesting(
                    action.template,
                    action.userId,
                    action.orgId,
                    defaultVSCodeStarter as any,
                    parameters,
                    { runUnitTests: true }
                  );
                return { generateCodeParameters };
              }),
              switchMap(({ generateCodeParameters }) => {
                return this.generateCodeService
                  .generateVsCodeDownloadCode(generateCodeParameters)
                  .pipe(
                    map((template: any) => {
                      console.log('template');
                      console.log(template);
                      this._snackBar.open(
                        `Test has been run for ${action.template.title}`,
                        undefined,
                        { duration: 3000 }
                      );
                      return GenerateCodeActions.runUnitTestsSuccess(template);
                    })
                  );
              })
            );
        },

        onError: (action, error) => {
          this._snackBar.open(
            `not connected to razroo-${action.template.corePathId}-starter`,
            undefined,
            { duration: 3000 }
          );
          console.error('Error', error);
          return GenerateCodeActions.runUnitTestsFailure({ error });
        },
      })
    )
  );

  runIntegrationTests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GenerateCodeActions.runIntegrationTests),
      fetch({
        run: (action) => {
          return this.vscodeService
            .getVsCodeAuthInfo(action.userId as string)
            .pipe(
              map((vsCodeInstances: VsCodeAuthInfo) => {
                const defaultVSCodeStarter =
                  this.generateCodeService.vsCodeDefaultStarter(
                    vsCodeInstances,
                    action.template
                  );
                if (!defaultVSCodeStarter) {
                  console.log('error');
                }
                const templateParameters = action.template.parameters
                  ? action.template.parameters
                  : [];
                const parameters =
                  this.generateCodeService.turnParametersIntoCodeGenerationSyntax(
                    templateParameters
                  );
                const generateCodeParameters =
                  this.generateCodeService.createGenerateCodeParametersForTesting(
                    action.template,
                    action.userId,
                    action.orgId,
                    defaultVSCodeStarter as any,
                    parameters,
                    { runIntegrationTests: true }
                  );
                return { generateCodeParameters };
              }),
              switchMap(({ generateCodeParameters }) => {
                return this.generateCodeService
                  .generateVsCodeDownloadCode(generateCodeParameters)
                  .pipe(
                    map((template: any) => {
                      this._snackBar.open(
                        `Test has been run for ${action.template.title}`,
                        undefined,
                        { duration: 3000 }
                      );
                      return GenerateCodeActions.runIntegrationTestsSuccess(
                        template
                      );
                    })
                  );
              })
            );
        },

        onError: (action, error) => {
          this._snackBar.open(
            `not connected to razroo-${action.template.corePathId}-starter`,
            undefined,
            { duration: 3000 }
          );
          console.error('Error', error);
          return GenerateCodeActions.runIntegrationTestsFailure({ error });
        },
      })
    )
  );

  runPreviewGeneration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GenerateCodeActions.runPreviewGeneration),
      fetch({
        run: (action) => {
          return this.vscodeService
            .getVsCodeAuthInfo(action.userId as string)
            .pipe(
              map((vsCodeInstances: VsCodeAuthInfo) => {
                const defaultVSCodeStarter =
                  this.generateCodeService.vsCodeDefaultStarter(
                    vsCodeInstances,
                    action.template
                  );
                if (!defaultVSCodeStarter) {
                  this._snackBar.open('no vscode starter');
                  console.log('error');
                }
                const templateParameters = action.template.parameters
                  ? action.template.parameters
                  : [];
                const parameters =
                  this.generateCodeService.turnParametersIntoCodeGenerationSyntax(
                    templateParameters
                  );
                const generateCodeParameters =
                  this.generateCodeService.createGenerateCodeParametersForTesting(
                    action.template,
                    action.userId,
                    action.orgId,
                    defaultVSCodeStarter as any,
                    parameters,
                    { runPreviewGeneration: true }
                  );
                return { generateCodeParameters };
              }),
              switchMap(({ generateCodeParameters }) => {
                return this.generateCodeService
                  .generateVsCodeDownloadCode(generateCodeParameters)
                  .pipe(
                    map((template: any) => {
                      this._snackBar.open(
                        `Preview generation has been run for ${action.template.title}`,
                        undefined,
                        { duration: 3000 }
                      );
                      return GenerateCodeActions.runPreviewGenerationSuccess(
                        template
                      );
                    })
                  );
              })
            );
        },

        onError: (action, error) => {
          this._snackBar.open(
            `not connected to razroo-${action.template.corePathId}-starter`,
            undefined,
            { duration: 3000 }
          );
          console.error('Error', error);
          return GenerateCodeActions.runPreviewGenerationFailure({ error });
        },
      })
    )
  );

  generateCodeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GenerateCodeActions.loadGenerateCodeSuccess),
      fetch({
        run: (action) => {
          // this.codeGenerationHistoryFacade.addGenerationToHistory(action.generateCode[0]);
          const template = action.template;
          const recommendationsStep: RecommendationsStep = {
            stepId: template.id,
            recipeId: template.recipeId,
            pathId: template.pathId,
            pathOrgId: template.orgId,
            executionId: template.executionId
          };

          if(action.newWorkspace){
            this.pathFacade.createProjectSuccess({
              id: _.kebabCase(action.parameters.name!) + '-1.0.0', 
              orgId: action.orgId!,
              baseCommunityPath: '',
              description:"New project",
              lastUpdated: Date.now().toString(),
              parentPath:null,
              project: 1,
              timestamp: Date.now().toString(),
              title: action.parameters.name!,
              userId: action.userId,
            })
          }
          this.generateCodeService.nextStepRecommendation(action.orgId as string, recommendationsStep, action.template.pathId).pipe(first()).subscribe((res: any) => {
            this.nextStepDialog(action, template, res)
          });     
        },
        onError: (action, error) => {
          console.error('Error', error);
          return GenerateCodeActions.loadGenerateCodeFailure({ error });
        },
      })
    )
  );

  updateOrganizationUserWithActiveProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GenerateCodeActions.loadGenerateCodeSuccess),
      fetch({
        run: (action) => {
          if (action.template.project && action.projectParams?.orgId && action.projectParams?.pathId) {
            return this.templateService.getPathTemplate(action.projectParams.orgId, action.projectParams.pathId).pipe(
              take(1),
              map((path: any) => {
                return GenerateCodeActions.updateActiveProject({activeProject: {
                  orgId: path.orgId,
                  id: path.id,
                  languageVersion: path.languageVersion,
                  baseCommunityPath: path.baseCommunityPath,
                  project: true,
                  title: path.title
                }});
              })
            );
          } else {
            // does not exist update path dropdown dynamically
            const parameters = typeof action.parameters === 'string' ? JSON.parse(action.parameters) : action.parameters;
            const coreProjectPathId = kebabCase((parameters?.name ? parameters.name : action.projectParams?.pathId));
            const {activeProject, githubReposUpdated} = this.generateCodeService.createNewActiveProjectAndReposObj(action, coreProjectPathId);
            return GenerateCodeActions.updateActiveProject({ activeProject, githubRepos: githubReposUpdated });
          }
        },
        onError: (action, error) => {
          console.error('Error', error);
          return GenerateCodeActions.loadGenerateCodeFailure({ error });
        },
      })
    )
  );

  nextStepDialog(action, template, nextStepRecommended: any = 'null'){
    const dialog = this.dialog.open(GoToNextStepDialogComponent, {
      data: {
        template: template,
        recommendedStep: nextStepRecommended,
        vsCodeInstances: action.vsCodeInstances,
        vsCodeInstanceId: (action.generateCode[0] as any)
        ?.vsCodeInstanceId,
        parameters: (action.generateCode[0] as any)?.parameters,
        codeGenPlatform: action.codeGenPlatform
      },
    });
    
    dialog
    .afterClosed()
    .pipe(take(1))
    .subscribe((res) => {
      if (res === 'next') {
        console.log('goToNextStep action: ', action);
        console.log('goToNextStep nextStepRecommended: ', nextStepRecommended);
        if (nextStepRecommended.batchId || nextStepRecommended.executionId) {
          this.templatesFacade.loadPathBatchTemplate(
            nextStepRecommended.orgId,
            nextStepRecommended.pathId,
            nextStepRecommended.executionId ? nextStepRecommended.executionId : nextStepRecommended.batchId,
            nextStepRecommended.recipeId,
            nextStepRecommended.id,
            false,
            true
          )
        } else { 
          this.templatesFacade.loadTemplate(
            nextStepRecommended.orgId,
            nextStepRecommended.pathId,
            nextStepRecommended.recipeId,
            nextStepRecommended.id
          );
        }
        
      }
    });
  }

  constructor(
    private actions$: Actions,
    private userFacade: UserFacade,
    private dialog: MatDialog,
    private generateCodeService: GenerateCodeService,
    private _snackBar: MatSnackBar,
    private localForageService: LocalForageService,
    private templatesFacade: TemplatesFacade,
    private codeGenerationHistoryFacade: CodeGenerationHistoryFacade,
    private vscodeService: VscodeService,
    private templateService: TemplateService,
    private pathFacade: PathFacade
  ) {}
}
