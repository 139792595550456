<h2 mat-dialog-title>Upgrade Plan</h2>

<div mat-dialog-content>
   <div class="BillingUpgrade__cards">
      <mat-card class="child-mat-card plan-card">
         <div class="ribbon"></div>
         <h3>Free</h3>
         <h3>USD $0/month</h3>
         <ul> 
           <li>10 Daily Code Generations</li>
           <li>5 Daily Ticket Generations</li>
         </ul>
         <button mat-raised-button disabled color="primary">Try Now</button>
       </mat-card>
    
       <mat-card class="child-mat-card plan-card">
          <div class="ribbon"></div>
          <h3>Razroo Teams (Monthly)</h3>
          <h3>USD $12.99/month</h3>
          <ul> 
            <li>Unlimited Code Generations</li>
            <li>Unlimited Ticket Generations</li>
          </ul>
          <button mat-raised-button color="accent" (click)="billingUpgradeMonthly(data.user)">
            Upgrade To Teams (Monthly)
            @if(upgradeMonthlyRedirecting) {
              <i class="fa-light fa-loader fa-spin" color="accent"></i>                  
            } @else {
              <i class="fa-solid fa-person-to-portal"></i>
            }
         </button>
       </mat-card>
    
       <mat-card class="child-mat-card plan-card">
         <div class="ribbon"></div>
         <h3>Razroo Teams (Annual)</h3>
         <h3>USD $9.99/month</h3>
         <ul> 
           <li>Unlimited Code Generations</li>
           <li>Unlimited Ticket Generations</li>
         </ul>
         <button mat-raised-button color="accent" (click)="billingUpgradeAnnual(data.user)">
           Upgrade To Teams (Annual)
           @if(upgradeAnnualRedirecting) {
             <i class="fa-light fa-loader fa-spin" color="accent"></i>                  
           } @else {
             <i class="fa-solid fa-person-to-portal"></i>
           }
         </button>
       </mat-card>
   </div>
</div>
