import gql from 'graphql-tag';

export const CreateStripeSession = gql`
  mutation createStripeCheckoutSession(
    $priceId: String!
    $orgId: String!
    $successUrl: String!
    $cancelUrl: String!
    $customerId: String
    $quantity: String
  ) {
    createStripeCheckoutSession(
      priceId: $priceId
      orgId: $orgId
      successUrl: $successUrl
      cancelUrl: $cancelUrl
      customerId: $customerId
      quantity: $quantity
    ) {
      id
      payment_status
      url
    }
  }
`;
export const UpdateStripeCheckoutSession = gql`
  mutation updateStripeCheckoutSession(
    $subscriptionId: String!,
    $successUrl: String!,
    $cancelUrl: String!,
    $customerId: String!
  ) {
    updateStripeCheckoutSession(
      subscriptionId: $subscriptionId,
      successUrl: $successUrl,
      cancelUrl: $cancelUrl,
      customerId: $customerId
    ) {
      id
      payment_status
      url
    }
  }
`;
