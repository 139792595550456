import { CreateSessionService, OrganizationsService, PricesService } from '@razroo-zeta/data-services';
import { Component, Inject, OnInit, NgModule } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { OrganizationUser } from '@razroo-zeta/data-models';
import { WindowRefService } from '@razroo-zeta/common-services';
import { take } from 'rxjs';

@Component({
  selector: 'razroo-zeta-billing-upgrade-dialog',
  templateUrl: './billing-upgrade-dialog.component.html',
  styleUrls: ['./billing-upgrade-dialog.component.scss'],
})
export class BillingUpgradeDialogComponent implements OnInit {
  upgradeMonthlyRedirecting = false;
  upgradeAnnualRedirecting = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      user: OrganizationUser
    },
    private dialogRef: MatDialogRef<BillingUpgradeDialogComponent>,
    private createSessionService: CreateSessionService,
    private windowRefService: WindowRefService,
    private organizationsService: OrganizationsService,
    private pricesService: PricesService
  ) {}

  ngOnInit(): void {  }
  
  billingUpgrade($event: any) {
    this.dialogRef.close($event);
  }

  billingUpgradeMonthly(user: OrganizationUser) {
    this.upgradeMonthlyRedirecting = true;
    this.pricesService
      .sendRequest()
      .pipe(take(1))
      .subscribe((res:any) => {
        // need orgnaization users to get quantity
        if(user.userId === user.orgId) {
          const quantity = 1;
          // if orgId then team membership otherwise personal
          const price = res['data'].find((price:any) => price.lookup_key === 'teams_monthly').id;
          this.createSessionService
            .createStripeCheckoutSession(price, user?.orgId, quantity)
            .pipe(take(1))
            .subscribe((res:any) => {
              this.upgradeMonthlyRedirecting = false;
              this.windowRefService.nativeWindow.location.href = res['url'];
            });
        } else {
          this.organizationsService.getOrganizationUsers(user.orgId).subscribe(organizationUsers => {
            const quantity = organizationUsers.length;
            // if orgId then team membership otherwise personal
            const price = res['data'].find((price:any) => price.lookup_key === 'teams_monthly').id;
            this.createSessionService
              .createStripeCheckoutSession(price, user?.orgId, quantity)
              .pipe(take(1))
              .subscribe((res:any) => {
                this.upgradeMonthlyRedirecting = false;
                this.windowRefService.nativeWindow.location.href = res['url'];
              });
          });
        }
        
    });
  }

  billingUpgradeAnnual(user: OrganizationUser) {
    this.upgradeAnnualRedirecting = true;
    this.pricesService
      .sendRequest()
      .pipe(take(1))
      .subscribe((res:any) => {
        // need orgnaization users to get quantity
        if(user.userId === user.orgId) {
          const quantity = 1;
          // if orgId then team membership otherwise personal
          const price = res['data'].find((price:any) => price.lookup_key === 'teams_monthly').id;
          this.createSessionService
            .createStripeCheckoutSession(price, user?.orgId, quantity)
            .pipe(take(1))
            .subscribe((res:any) => {
              this.upgradeAnnualRedirecting = false;
              this.windowRefService.nativeWindow.location.href = res['url'];
            });
        } else {
          this.organizationsService.getOrganizationUsers(user.orgId).subscribe(organizationUsers => {
            const quantity = organizationUsers.length;
            // if orgId then team membership otherwise personal
            const price = res['data'].find((price:any) => price.lookup_key === 'teams_annual').id;
            this.createSessionService
              .createStripeCheckoutSession(price, user?.orgId, quantity)
              .pipe(take(1))
              .subscribe((res:any) => {
                this.upgradeAnnualRedirecting = false;
                this.windowRefService.nativeWindow.location.href = res['url'];
              });
          });
        } 
    });
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule
  ],
  declarations: [BillingUpgradeDialogComponent],
  exports: [BillingUpgradeDialogComponent],
})
export class BillingUpgradeComponentModule {}
