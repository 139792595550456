import { Injectable } from '@angular/core';
import { CreateTicket, CreateTicketWorkspace, GenerateProductTicketTitlesAndUpload, GenerateProductTicketTitles,
  GetTicketWorkspaces, GetTicketWorkspace, GetWorkspaceTicket, GetTicketWorkspaceTickets, 
  DeleteTicketWorkspace, DeleteTicketWorkspaceTicket, GenerateDescriptionForProductTicketAndUpdate, 
  UpdateTicketWorkspaceTicket, UploadJiraApiKeyAndJiraOrg, UploadTicketToJira, GetJiraProjects, 
  GenerateEntireProductTicketUsingAiAndUpload, GetJiraTicket, EditPartOfTicketDescriptionBasedOnPrompt, 
  AddTicketDescriptionBackground, AddTicketTechnicalNotes, AddTicketCodeSnippet, 
  GetTicketHistory, GenerateUserJourneyForProductTicket, 
  GenerateAcceptanceCriteriaForProductTicket, GetTicketWorkspaceSprintBoards, 
  UpdateTicketWorkspaceBoard, GetTicketWorkspaceBoard, GetTicketMediaUploadUrl, 
  GetJiraBoards, UpdateWorkspaceTicketColumn, SearchTickets, GetCompletedWorkspaceBoards, 
  GenerateFollowUpProductTicketTitles, ModifyTicketBasedOnTitleUsingAi, CreateOnDemandEpic, 
  GetTicketsWithSameEpicId,
  GetSimilarEpics,
  GetAllTicketWorkspaceEpics, UnlinkParentTicketFromTicket, CreateEpicProductTicketUsingAi, 
  GetAllTitleTicketsForWorkspaceEpic, DeleteTitleTicket, SuggestNewEpics} from '@razroo-zeta/data-graphql';
import { Ticket, TicketWorkspaceUpdateParams, TicketWorkspaceTicketUpdateParams, 
  TicketWorkspaceBoardUpdateParams, ClarificationQuestionResponse, UpdateTicketColumnParams,
  TicketWorkspaceBoardColumn, TitleTicket } from '@razroo-zeta/data-models';
import { Apollo } from 'apollo-angular';
import { GenerateEstimatedStoryPointsReasonForProductTicket, CreateTicketWorkspaceBoard, GenerateBusinessValueForProductTicket, GenerateFollowUpQuestionsForProductTicket, UpdateTicketWorkspace, DeleteTicketWorkspaceBoard, AddUserToTicketWorkspace, RemoveTicketMedia, AddTicketScenario, MoveCardInKanbanColumn, UploadTicketWorkspaceGraphqlSchema, UploadTicketWorkspaceZeplinImages, RemoveUserFromTicketWorkspace, SubscribeTicketWorkspaceToSlackChannel, ImportJiraTickets, SubscribeToTicket, UnsubscribeFromTicket, RenameKanbanColumn, CreateKanbanColumn, DeleteKanbanColumn, ReorderKanbanColumns, UpdateTicketOrder} from 'libs/data-graphql/src/lib/tickets/tickets.mutations';
import { AbridgedTicket, MoveKanbanCardParamsInputAlt } from 'libs/data-models/src/lib/tickets/tickets.interface';
import { from } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  constructor(private apollo: Apollo) { }
  createTicket(
    orgId: string,
    workspaceId: string,
    ticketTitle: string,
    type?: string,
    content?: string
  ){
    const mutation = CreateTicket;
    const variables = {
      orgId,
      workspaceId,
      ticketTitle,
      type,
      content
    }
    const createTicket$ = this.apollo.mutate({mutation, variables});
    return from(createTicket$).pipe(pluck('data', 'createTicket'));
  };

  createTicketWorkspace(
    orgId: string,
    workspaceName: string,
    jiraProject: string,
    description?: string
  ){
    const mutation = CreateTicketWorkspace;
    const variables = {
      orgId,
      workspaceName,
      jiraProject,
      description
    }
    const createTicketWorkspace$ = this.apollo.mutate({mutation, variables});
    return from(createTicketWorkspace$).pipe(pluck('data', 'createTicketWorkspace'));
  }

  updateTicketWorkspace(orgId: string, workspaceId: string, ticketWorkspaceUpdateParams: TicketWorkspaceUpdateParams){
    const mutation = UpdateTicketWorkspace;
    const variables = {
      orgId,
      workspaceId,
      ticketWorkspaceUpdateParams
    }
    const updateTicketWorkspace$ = this.apollo.mutate({mutation, variables});
    return from(updateTicketWorkspace$).pipe(pluck('data', 'updateTicketWorkspace'));
  }

  getTicketWorkspace(orgId: string, workspaceId: string){
    const query = GetTicketWorkspace;
    const variables = {
      orgId,
      workspaceId
    }
    const getTicketWorkspace$ = this.apollo.query({ query, variables, fetchPolicy: 'no-cache' });
    return from(getTicketWorkspace$).pipe(pluck('data', 'getTicketWorkspace'));
  }

  getTicketWorkspaces(orgId: string) {
    const query = GetTicketWorkspaces;
    const variables = {
      orgId,
    };

    const getTicketWorkspaces$ = this.apollo.query({ query, variables, fetchPolicy: 'no-cache' });
    return from(getTicketWorkspaces$).pipe(pluck('data', 'getTicketWorkspaces'));
  }

  getWorkspaceTicket(orgId: string, workspaceId: string, ticketNumber: number, noCache: boolean = false) {
    const query = GetWorkspaceTicket;
    const variables = {
      orgId,
      workspaceId,
      ticketNumber
    };

    const getWorkspaceTicket$ = this.apollo.query({ query, variables, fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
    return from(getWorkspaceTicket$).pipe(pluck('data', 'getWorkspaceTicket'));
  };

  deleteTicketWorkspace(orgId: string, workspaceId: string){
    const mutation = DeleteTicketWorkspace;
    const variables = {
      orgId, 
      workspaceId
    };
    const deleteTicketWorkspace$ = this.apollo.mutate({ mutation, variables });
    return from(deleteTicketWorkspace$).pipe(pluck('data', 'deleteTicketWorkspace'));
  }

  deleteTicketWorkspaceTicket(orgId: string, workspaceId: string, ticketNumber: number){
    const mutation = DeleteTicketWorkspaceTicket;
    const variables = {
      orgId, 
      workspaceId,
      ticketNumber
    };
    const deleteTicketWorkspaceTicket$ = this.apollo.mutate({ mutation, variables });
    return from(deleteTicketWorkspaceTicket$).pipe(pluck('data', 'deleteTicketWorkspaceTicket'));
  }

  getTicketWorkspaceTickets(orgId: string, workspaceId: string, boardId?: number | undefined) {
    const query = GetTicketWorkspaceTickets;
    const variables = {
      orgId,
      workspaceId,
      boardId
    };

    const getTicketWorkspaceTickets$ = this.apollo.query({ query, variables, fetchPolicy: 'no-cache' });
    return from(getTicketWorkspaceTickets$).pipe(pluck('data', 'getTicketWorkspaceTickets'));
  }

  getTicketsWithSameEpicId(orgId: string, workspaceId: string, epicId?: string) {
    const query = GetTicketsWithSameEpicId;
    const variables = {
      orgId,
      workspaceId,
      epicId
    };

    const getTicketsWithSameEpicId$ = this.apollo.query({ query, variables, fetchPolicy: 'no-cache' });
    return from(getTicketsWithSameEpicId$).pipe(map((response: any) => response.data.getTicketsWithSameEpicId));
  }

  getAllTicketWorkspaceEpics(orgId: string, workspaceId: string) {
    const query = GetAllTicketWorkspaceEpics;
    const variables = {
      orgId,
      workspaceId
    };

    const getAllTicketWorkspaceEpics$ = this.apollo.query({ query, variables, fetchPolicy: 'no-cache' });
    return from(getAllTicketWorkspaceEpics$).pipe(map((response: any) => response.data.getAllTicketWorkspaceEpics));
  }

  getSimilarEpics(orgId: string, workspaceId: string, epicId?: string) {
    const query = GetSimilarEpics;
    const variables = {
      orgId,
      workspaceId,
      epicId
    };

    const getSimilarEpics$ = this.apollo.query({ query, variables, fetchPolicy: 'no-cache' });
    return from(getSimilarEpics$).pipe(map((response: any) => response.data.getSimilarEpics));
  }

  generateProductTicketTitlesAndUpload(
    orgId: string,
    workspaceId: string,
    currentEpicTickets: AbridgedTicket[],
    userProductMessage: string,
    parentTicketNumber?: number,
    epic?: string,
    startingTicketNumber?: number,
    extraDetails?: string
  ){
    const mutation = GenerateProductTicketTitlesAndUpload;
    const variables = {
      orgId,
      workspaceId,
      userProductMessage,
      currentEpicTickets,
      parentTicketNumber,
      epic,
      startingTicketNumber,
      extraDetails
    };
    const generateProductTicketTitlesAndUpload$ = this.apollo.mutate({mutation, variables});
    return from(generateProductTicketTitlesAndUpload$).pipe(pluck('data', 'generateProductTicketTitlesAndUpload'));
  }

  getAllTitleTicketsForWorkspaceEpic(orgId: string, workspaceId: string, parentTicketNumber?: number) {
    const query = GetAllTitleTicketsForWorkspaceEpic;
    const variables = {
      orgId,
      workspaceId,
      parentTicketNumber
    };

    const getAllTitleTicketsForWorkspaceEpic$ = this.apollo.query({ query, variables, fetchPolicy: 'no-cache' });
    return from(getAllTitleTicketsForWorkspaceEpic$).pipe(
      map((response: any) => response.data.getAllTitleTicketsForWorkspaceEpic)
    );
  }

  deleteTitleTicket(orgId: string, workspaceId: string, parentTicketNumber: number, ticketNumber: number) {
    const mutation = DeleteTitleTicket;
    const variables = {
      orgId,
      workspaceId,
      parentTicketNumber,
      ticketNumber
    };
    const deleteTitleTicket$ = this.apollo.mutate({ mutation, variables });
    return from(deleteTitleTicket$).pipe(
      map((response: any) => response.data.deleteTitleTicket)
    );
  }

  generateProductTicketTitles(
    orgId: string,
    workspaceId: string,
    currentEpicTickets: TitleTicket[],
    userProductMessage: string,
    parentTicketNumber?: number,
    epic?: string,
    startingTicketNumber?: number
  ){
    const mutation = GenerateProductTicketTitles;
    const variables = {
      orgId,
      workspaceId,
      userProductMessage,
      currentEpicTickets,
      parentTicketNumber,
      epic,
      startingTicketNumber
    };
    const generateProductTicketTitles$ = this.apollo.mutate({mutation, variables});

    return from(generateProductTicketTitles$).pipe(map((response: any) => response.data.generateProductTicketTitles));

  }

  generateFollowUpProductTicketTitles(
    ticketsAlreadyGenerated: string, 
    startingTicketNumber: number
  ){
    const mutation = GenerateFollowUpProductTicketTitles;
    const variables = {
      ticketsAlreadyGenerated, 
      startingTicketNumber
    };
    const generateFollowUpProductTicketTitles$ = this.apollo.mutate({mutation, variables});

    return from(generateFollowUpProductTicketTitles$).pipe(map((response: any) => response.data.generateFollowUpProductTicketTitles));
  }

  addNewTicketToKanbanColumns(kanbanColumns: TicketWorkspaceBoardColumn[], ticket: Ticket): TicketWorkspaceBoardColumn[] {
    // Create a deep copy of the kanbanColumns array
    const kanbanColumnsClone = kanbanColumns.map(column => ({
      ...column,
      tickets: [...column.tickets]
    }));

    // Add the new ticket to the first column's tickets array
    kanbanColumnsClone[0].tickets.push(ticket);
    return kanbanColumnsClone;
  }



  generateEntireProductTicketUsingAiAndUpload(orgId: string, workspaceId: string, userTicketPrompt: string, epic?: string, 
    boardId?: number, followUpResponses?: ClarificationQuestionResponse[], parentTicketNumber?: number){
    const mutation = GenerateEntireProductTicketUsingAiAndUpload;
    const variables = {
      orgId, 
      workspaceId, 
      userTicketPrompt,
      jiraTicketKey: undefined,
      epic,
      boardId,
      followUpResponses,
      parentTicketNumber
    };
    const generateEntireProductTicketUsingAiAndUpload$ = this.apollo.mutate({mutation, variables});
    return from(generateEntireProductTicketUsingAiAndUpload$).pipe(pluck('data', 'generateEntireProductTicketUsingAiAndUpload'));
  }

  generateDescriptionForProductTicketAndUpdate(
    ticket: Ticket
  ){
    const mutation = GenerateDescriptionForProductTicketAndUpdate;
    const variables = {
      orgId: ticket.orgId,
      workspaceId: ticket.workspaceId,
      ticketNumber: ticket.ticketNumber,
      title: ticket.title,
      description: ticket.description ? ticket.description : ticket.shortDescription,
      epic: ticket.epic,
      type: ticket.type,
      labels: ticket.labels
    }
    const generateDescriptionForProductTicketAndUpdate$ = this.apollo.mutate({mutation, variables});
    return from(generateDescriptionForProductTicketAndUpdate$).pipe(pluck('data', 'generateDescriptionForProductTicketAndUpdate'));
  }

  editPartOfTicketDescriptionBasedOnPrompt(
    orgId: string, workspaceId: string, ticketNumber: number, 
    descriptionToModify: string, textToUpdate: string, userPrompt: string
  ){
    const mutation = EditPartOfTicketDescriptionBasedOnPrompt;
    const variables = {
      orgId, 
      workspaceId, 
      ticketNumber, 
      descriptionToModify, 
      textToUpdate, 
      userPrompt
    };

    const editPartOfTicketDescriptionBasedOnPrompt$ = this.apollo.mutate({mutation, variables});
    return from(editPartOfTicketDescriptionBasedOnPrompt$).pipe(map((response: any) => response.data.editPartOfTicketDescriptionBasedOnPrompt));
  }

  updateTicketWorkspaceTicket(
    orgId: string, 
    workspaceId: string, 
    ticketNumber: number, 
    ticketUpdateParams: TicketWorkspaceTicketUpdateParams
  ){
    const mutation = UpdateTicketWorkspaceTicket;
    const variables = {
      orgId,
      workspaceId,
      ticketNumber,
      ticketUpdateParams
    };
    const updateTicketWorkspaceTicket$ = this.apollo.mutate({mutation, variables});
    return from(updateTicketWorkspaceTicket$).pipe(map((res: any) => res.data.updateTicketWorkspaceTicket));
  }

  updateWorkspaceTicketColumn(
    orgId: string, 
    workspaceId: string, 
    ticketNumber: number, 
    updateTicketColumnParams: UpdateTicketColumnParams
  ){
    const mutation = UpdateWorkspaceTicketColumn;
    const variables = {
      orgId,
      workspaceId,
      ticketNumber,
      updateTicketColumnParams
    };
    const updateWorkspaceTicketColumn$ = this.apollo.mutate({mutation, variables});
    return from(updateWorkspaceTicketColumn$).pipe(map((res: any) => res.data.updateWorkspaceTicketColumn));
  }

  getTicketWorkspaceBoard(
    orgId: string, 
    workspaceId: string, 
    boardId: number
  ){
    const mutation = GetTicketWorkspaceBoard;
    const variables = {
      orgId,
      workspaceId,
      boardId
    };
    const getTicketWorkspaceBoard$ = this.apollo.mutate({mutation, variables});
    return from(getTicketWorkspaceBoard$).pipe(map((response: any) => response.data.getTicketWorkspaceBoard));
  }

  uploadJiraApiKeyAndJiraOrg(
    orgId: string, 
    jiraOrgId: string, 
    jiraApiKey: string
  ){
    const mutation = UploadJiraApiKeyAndJiraOrg;
    const variables = {
      orgId,
      jiraOrgId,
      jiraApiKey
    };
    const uploadJiraApiKeyAndJiraOrg$ = this.apollo.mutate({mutation, variables});
    return from(uploadJiraApiKeyAndJiraOrg$).pipe(map((data: any) => data.data.uploadJiraApiKeyAndJiraOrg));
  }

  uploadTicketToJira(
    orgId: string,
    workspaceId: string,
    ticketNumber: number,
    jiraInstance: string,
    projectKey: string | undefined = undefined,
    issueType: string,
    summary: string,
    description: string,
    labels?: string[],
    estimatedStoryPoints?: number,
    assigneeEmail?: string
  ){
    const mutation = UploadTicketToJira;
    const variables = {
      orgId,
      workspaceId,
      ticketNumber,
      jiraInstance,
      projectKey,
      issueType,
      summary,
      description,
      labels,
      estimatedStoryPoints,
      assigneeEmail
    }

    const uploadTicketToJira$ = this.apollo.mutate({mutation, variables});
    return from(uploadTicketToJira$).pipe(pluck('data', 'uploadTicketToJira'));
  }

  getJiraTicket(orgId: string, workspaceId: string, jiraInstance: string, issueNumber: string){
    const query = GetJiraTicket;
    const variables = {
      orgId, 
      workspaceId,
      jiraInstance,
      issueNumber
    }
    const getJiraTicket$ = this.apollo.query({query, variables});
    return from(getJiraTicket$).pipe(pluck('data', 'getJiraTicket')); 
  }

  getJiraProjects(orgId: string, jiraInstance: string){
    const query = GetJiraProjects;
    const variables = {
      orgId, 
      jiraInstance
    }
    const getJiraProjects$ = this.apollo.query({query, variables});
    return from(getJiraProjects$).pipe(pluck('data', 'getJiraProjects'));
  }

  addTicketDescriptionBackground(orgId: string, workspaceId: string, ticketNumber: number, description: string){
    const mutation = AddTicketDescriptionBackground;
    const variables = {
      orgId, 
      workspaceId,
      ticketNumber,
      description
    }
    const addTicketDescriptionBackground$ = this.apollo.mutate({mutation, variables});
    return from(addTicketDescriptionBackground$).pipe(pluck('data', 'addTicketDescriptionBackground'));
  }

  addTicketTechnicalNotes(orgId: string, workspaceId: string, ticketNumber: number, description: string){
    const mutation = AddTicketTechnicalNotes;
    const variables = {
      orgId, 
      workspaceId,
      ticketNumber,
      description
    }
    const addTicketTechnicalNotes$ = this.apollo.mutate({mutation, variables});
    return from(addTicketTechnicalNotes$).pipe(pluck('data', 'addTicketTechnicalNotes'));
  }

  addTicketCodeSnippet(orgId: string, workspaceId: string, ticketNumber: number, description: string){
    const mutation = AddTicketCodeSnippet;
    const variables = {
      orgId, 
      workspaceId,
      ticketNumber,
      description
    }
    const addTicketCodeSnippet$ = this.apollo.mutate({mutation, variables});
    return from(addTicketCodeSnippet$).pipe(pluck('data', 'addTicketCodeSnippet'));
  }

  addTicketScenario(orgId: string, workspaceId: string, ticketNumber: number, description: string, userPrompt: string){
    const mutation = AddTicketScenario;
    const variables = {
      orgId,
      workspaceId,
      ticketNumber,
      description,
      userPrompt
    }
    const addTicketScenario$ = this.apollo.mutate({mutation, variables});
    return from(addTicketScenario$).pipe(map((res: any) => res.data.addTicketScenario));
  }
  
  getTicketHistory(orgId: string, workspaceId: string, ticketNumber: number){
    const query = GetTicketHistory;
    const variables = {
      orgId, 
      workspaceId, 
      ticketNumber
    }
    const getTicketHistory$ = this.apollo.query({query, variables, fetchPolicy: 'no-cache'});
    return from(getTicketHistory$).pipe(pluck('data', 'getTicketHistory'));
  }

  generateUserJourney(orgId: string, workspaceId: string, ticketNumber: number, description: string){
    const mutation = GenerateUserJourneyForProductTicket;
    const variables = {
      orgId,
      workspaceId,
      ticketNumber,
      description
    };
    const generateUserJourneyForProductTicket$ = this.apollo.mutate({mutation, variables});
    return from(generateUserJourneyForProductTicket$).pipe(map((res:any) => res.data.generateUserJourneyForProductTicket))
  }

  generateAcceptanceCriteriaForProductTicket(orgId: string, workspaceId: string, ticketNumber: number, description: string){
    const mutation = GenerateAcceptanceCriteriaForProductTicket;
    const variables = {
      orgId,
      workspaceId,
      ticketNumber,
      description
    };
    const generateAcceptanceCriteriaForProductTicket$ = this.apollo.mutate({mutation, variables});
    return from(generateAcceptanceCriteriaForProductTicket$).pipe(map((res:any) => res.data.generateAcceptanceCriteriaForProductTicket));
  }
  generateBusinessValueForProductTicket(orgId: string, workspaceId: string, ticketNumber: number, description: string){
    const mutation = GenerateBusinessValueForProductTicket;
    const variables = {
      orgId,
      workspaceId,
      ticketNumber,
      description
    };
    const generateBusinessValueForProductTicket$ = this.apollo.mutate({mutation, variables});
    return from(generateBusinessValueForProductTicket$).pipe(map((res:any) => res.data.generateBusinessValueForProductTicket));
  }
  
  generateEstimatedStoryPointsReasonForProductTicket(
    orgId: string, 
    workspaceId: string, 
    ticketNumber: number, 
    description: string, 
    estimatedStoryPoints: number
  ){
    const mutation = GenerateEstimatedStoryPointsReasonForProductTicket;
    const variables = {
      orgId,
      workspaceId,
      ticketNumber,
      description,
      estimatedStoryPoints
    }
    const generateEstimatedStoryPointsReasonForProductTicket$ = this.apollo.mutate({mutation, variables});
    return from(generateEstimatedStoryPointsReasonForProductTicket$).pipe(map((res:any) => res.data.generateEstimatedStoryPointsReasonForProductTicket));
  }

  createTicketWorkspaceBoard(orgId: string, workspaceId: string, boardName: string, startDate?: string, endDate?: string, description?: string){
    const mutation = CreateTicketWorkspaceBoard;
    const variables = {
      orgId,
      workspaceId,
      boardName,
      startDate,
      endDate,
      description
    }
    const createTicketWorkspaceBoard$ = this.apollo.mutate({mutation, variables});
    return from(createTicketWorkspaceBoard$).pipe(map((res:any) => res.data.createTicketWorkspaceBoard));
  }

  modifyTicketBasedOnTitleUsingAi(orgId: string, workspaceId: string, ticketNumber: number, 
    existingDescription: string, existingTitle: string, updatedTitle: string){
    const mutation = ModifyTicketBasedOnTitleUsingAi;
    const variables = {
      orgId,
      workspaceId,
      ticketNumber,
      existingDescription,
      existingTitle,
      updatedTitle
    }
    const modifyTicketBasedOnTitleUsingAi$ = this.apollo.mutate({mutation, variables});
    return from(modifyTicketBasedOnTitleUsingAi$).pipe(map((res:any) => res.data.modifyTicketBasedOnTitleUsingAi));
  }

  createOnDemandEpic(orgId: string, workspaceId: string, ticketNumber: number, 
    epicName: string){
    const mutation = CreateOnDemandEpic;
    const variables = {
      orgId,
      workspaceId,
      ticketNumber,
      epicName
    }
    const modifyTicketBasedOnTitleUsingAi$ = this.apollo.mutate({mutation, variables});
    return from(modifyTicketBasedOnTitleUsingAi$).pipe(map((res:any) => res.data.createOnDemandEpic));
  }

  unlinkParentTicketFromTicket(orgId: string, workspaceId: string, ticketNumber: number){
    const mutation = UnlinkParentTicketFromTicket;
    const variables = {
      orgId,
      workspaceId,
      ticketNumber
    }
    const unlinkParentTicketFromTicket$ = this.apollo.mutate({mutation, variables});
    return from(unlinkParentTicketFromTicket$).pipe(map((res:any) => res.data.unlinkParentTicketFromTicket));
  }

  getTicketWorkspaceSprintBoards(orgId: string, workspaceId: string, includeBacklog?: boolean){
    const query = GetTicketWorkspaceSprintBoards;
    const variables = {
      orgId,
      workspaceId, 
      includeBacklog
    }
    const getTicketWorkspaceSprintBoards$ = this.apollo.query({query, variables, fetchPolicy: 'network-only'});
    return from(getTicketWorkspaceSprintBoards$).pipe(map((res:any) => res.data.getTicketWorkspaceSprintBoards));
  }

  getCompletedWorkspaceBoards(orgId: string, workspaceId: string){
    const query = GetCompletedWorkspaceBoards;
    const variables = {
      orgId,
      workspaceId
    }
    const getCompletedWorkspaceBoards$ = this.apollo.query({query, variables, fetchPolicy: 'no-cache'});
    return from(getCompletedWorkspaceBoards$).pipe(map((res:any) => res.data.getCompletedWorkspaceBoards));
  }

  updateTicketWorkspaceBoard(
    orgId: string, 
    workspaceId: string, 
    boardId: number, 
    boardUpdateParams: TicketWorkspaceBoardUpdateParams
  ){
    const mutation = UpdateTicketWorkspaceBoard;
    const variables = {
      orgId,
      workspaceId,
      boardId,
      boardUpdateParams
    }
    const updateTicketWorkspaceBoard$ = this.apollo.mutate({mutation, variables});
    return from(updateTicketWorkspaceBoard$).pipe(map((res:any) => res.data.updateTicketWorkspaceBoard));
  }

  deleteTicketWorkspaceBoard(
    orgId: string, 
    workspaceId: string, 
    boardId: number,
    newBoardId?: number
  ){
    const mutation = DeleteTicketWorkspaceBoard;
    const variables = {
      orgId,
      workspaceId,
      boardId,
      newBoardId
    }
    const deleteTicketWorkspaceBoard$ = this.apollo.mutate({mutation, variables});
    return from(deleteTicketWorkspaceBoard$).pipe(map((res:any) => res.data.deleteTicketWorkspaceBoard));
  }

  generateFollowUpQuestionsForProductTicket(
    orgId: string,
    workspaceId: string,
    userPrompt: string,
    
  ){
    const mutation = GenerateFollowUpQuestionsForProductTicket;
    const variables = {
      orgId,
      workspaceId,
      userPrompt,
    }
    const generateFollowUpQuestionsForProductTicket$ = this.apollo.mutate({mutation, variables});
    return from(generateFollowUpQuestionsForProductTicket$).pipe(map((res:any) => res.data.generateFollowUpQuestionsForProductTicket));
  }

  addUserToTicketWorkspace(orgId: string, workspaceId: string, newUserId: string){
    const mutation = AddUserToTicketWorkspace;
    const variables = {
      orgId,
      workspaceId,
      newUserId
    }
    const addUserToTicketWorkspace$ = this.apollo.mutate({mutation, variables});
    return from(addUserToTicketWorkspace$).pipe(map((res:any) => res.data.addUserToTicketWorkspace));
  };

  getTicketMediaUploadUrl(orgId: string, workspaceId: string, ticketNumber: number, fileName: string){
    const query = GetTicketMediaUploadUrl;
    const variables = {
      orgId,
      workspaceId,
      ticketNumber,
      fileName
    }
    const getTicketMediaUploadUrl$ = this.apollo.query({query, variables});
    return from(getTicketMediaUploadUrl$).pipe(map((res:any) => res.data.getTicketMediaUploadUrl));
  }

  createEpicProductTicketUsingAi(orgId: string, workspaceId: string, userTicketPrompt: string){
    const mutation = CreateEpicProductTicketUsingAi;
    const variables = {
      orgId,
      workspaceId,
      userTicketPrompt
    }
    const createEpicProductTicketUsingAi$ = this.apollo.mutate({mutation, variables});
    return from(createEpicProductTicketUsingAi$).pipe(map((res:any) => res.data.createEpicProductTicketUsingAi));
  }

  moveCardInKanbanColumn(orgId: string, workspaceId: string, boardId: number, moveKanbanCardParams: MoveKanbanCardParamsInputAlt){
    const mutation = MoveCardInKanbanColumn;
    const variables = {
      orgId,
      workspaceId,
      boardId,
      moveKanbanCardParams
    }
    const moveCardInKanbanColumn$ = this.apollo.mutate({mutation, variables});
    return from(moveCardInKanbanColumn$).pipe(map((res:any) => res.data.moveCardInKanbanColumn));
  }

  removeTicketMedia(orgId: string, workspaceId: string, ticketNumber: number, fileName: string){
    const mutation = RemoveTicketMedia;
    const variables = {
      orgId,
      workspaceId,
      ticketNumber,
      fileName
    }
    const removeTicketMedia$ = this.apollo.mutate({mutation, variables});
    return from(removeTicketMedia$).pipe(map((res:any) => res.data.removeTicketMedia));
  }

  uploadTicketWorkspaceGraphqlSchema(orgId: string, workspaceId: string, endpointUrl: string, accessToken: string){
    const mutation = UploadTicketWorkspaceGraphqlSchema;
    const variables = {
      orgId,
      workspaceId,
      endpointUrl,
      accessToken
    };
    const uploadTicketWorkspaceGraphqlSchema$ = this.apollo.mutate({mutation, variables});
    return from(uploadTicketWorkspaceGraphqlSchema$).pipe(map((res:any) => res.data.uploadTicketWorkspaceGraphqlSchema));
  }
  uploadTicketWorkspaceZeplinImages(orgId: string, workspaceId: string, zeplinProjectId: string, accessToken: string){
    const mutation = UploadTicketWorkspaceZeplinImages;
    const variables = {
      orgId,
      workspaceId,
      zeplinProjectId,
      accessToken
    }
    const uploadTicketWorkspaceZeplinImages$ = this.apollo.mutate({mutation, variables});
    return from(uploadTicketWorkspaceZeplinImages$).pipe(map((res:any) => res.data.uploadTicketWorkspaceZeplinImages));
  }
  removeUserFromTicketWorkspace(orgId: string, workspaceId: string, userId: string){
    const mutation = RemoveUserFromTicketWorkspace;
    const variables = {
      orgId,
      workspaceId,
      userId
    }
    const removeUserFromTicketWorkspace$ = this.apollo.mutate({mutation, variables});
    return from(removeUserFromTicketWorkspace$).pipe(map((res:any) => res.data.removeUserFromTicketWorkspace));
  }

  subscribeTicketWorkspaceToSlackChannel(orgId: string, workspaceId: string, slackChannelId: string, slackChannelName: string){
    const mutation = SubscribeTicketWorkspaceToSlackChannel;
    const variables = {
      orgId, 
      workspaceId,
      slackChannelId,
      slackChannelName
    };
    const subscribeTicketWorkspaceToSlackChannel$ = this.apollo.mutate({mutation, variables});
    return from(subscribeTicketWorkspaceToSlackChannel$).pipe(map((res: any) => res.data.subscribeTicketWorkspaceToSlackChannel));
  }

  getJiraBoards(orgId: string, jiraOrgId: string){
    const query = GetJiraBoards;
    const variables = {
      orgId, 
      jiraOrgId
    };
    const getJiraBoards$ = this.apollo.query({query, variables});
    return from(getJiraBoards$).pipe(map((res: any) => res.data.getJiraBoards));
  }

  importJiraTickets(orgId: string, workspaceId: string, jiraOrgId: string, boardId: number){
    const mutation = ImportJiraTickets;
    const variables = {
      orgId, 
      workspaceId,
      jiraOrgId,
      boardId
    };
    const importJiraTickets$ = this.apollo.mutate({mutation, variables});
    return from(importJiraTickets$).pipe(map((res: any) => res.data.importJiraTickets));
  }

  subscribeToTicket(orgId: string, workspaceId: string, ticketNumber: number){
    const mutation = SubscribeToTicket;
    const variables =  {
      orgId, 
      workspaceId,
      ticketNumber
    }
    const subscribeToTicket$ = this.apollo.mutate({mutation, variables});
    return from(subscribeToTicket$).pipe(map((res: any) => res.data.subscribeToTicket));
  };

  unsubscribeFromTicket(orgId: string, workspaceId: string, ticketNumber: number){
    const mutation = UnsubscribeFromTicket;
    const variables =  {
      orgId, 
      workspaceId,
      ticketNumber
    }
    const unsubscribeFromTicket$ = this.apollo.mutate({mutation, variables});
    return from(unsubscribeFromTicket$).pipe(map((res: any) => res.data.unsubscribeFromTicket));
  };

  renameKanbanColumn(orgId: string, workspaceId: string, columnId: string, newColumnName: string, boardId: number){
    const mutation = RenameKanbanColumn;
    const variables = {
      orgId,
      workspaceId,
      columnId,
      newColumnName,
      boardId
    }
    const renameKanbanColumn$ = this.apollo.mutate({mutation, variables});
    return from(renameKanbanColumn$).pipe(map((res:any) => res.data.renameKanbanColumn))
  };

  createKanbanColumn(orgId: string, workspaceId: string, columnName: string, boardId: number){
    const mutation = CreateKanbanColumn;
    const variables = {
      orgId, 
      workspaceId,
      columnName,
      boardId
    }
    const createKanbanColumn$ = this.apollo.mutate({mutation, variables});
    return from(createKanbanColumn$).pipe(map((res:any) => res.data.createKanbanColumn))
  };

  deleteKanbanColumn(orgId: string, workspaceId: string, columnId: string, newColumnId?: string, boardId?: number){
    const mutation = DeleteKanbanColumn;
    const variables = {
      orgId,
      workspaceId,
      columnId,
      newColumnId,
      boardId
    }
    const deleteKanbanColumn$ = this.apollo.mutate({mutation, variables});
    return from(deleteKanbanColumn$).pipe(map((res:any) => res.data.deleteKanbanColumn));
  }

  reorderKanbanColumns(orgId: string, workspaceId: string, boardColumns: string[], boardId: number){
    const mutation = ReorderKanbanColumns;
    const variables = {
      orgId,
      workspaceId,
      boardColumns,
      boardId
    }
    const reorderKanbanColumns$ = this.apollo.mutate({mutation, variables});
    return from(reorderKanbanColumns$).pipe(map((res:any) => res.data.reorderKanbanColumns));
  }

  updateTicketOrder(orgId: string, workspaceId: string, ticketsOrder: number[], boardId: number){
    const mutation = UpdateTicketOrder;
    const variables = {
      orgId,
      workspaceId,
      ticketsOrder,
      boardId
    }
    const updateTicketOrder$ = this.apollo.mutate({mutation, variables});
    return from(updateTicketOrder$).pipe(map((res:any) => res.data.updateTicketOrder));
  }
  searchTickets( 
    search: string,
    searchFields: string[],
    orgId: string,
    workspaceId: string,
    size: number,
    fromNum: number
  ){
    const query = SearchTickets;
    const variables = {
      search,
      searchFields,
      orgId,
      workspaceId,
      size,
      from: fromNum
    }
    const searchTickets$ = this.apollo.query({query, variables});
    return from(searchTickets$).pipe(map((res: any) => res.data.searchTickets));
  }

  suggestNewEpics(orgId: string, workspaceId: string, epicTitles: string[], businessDescription: string, targetAudience: string) {
    const mutation = SuggestNewEpics;
    const variables = {
      orgId,
      workspaceId,
      epicTitles,
      businessDescription,
      targetAudience
    };
    const suggestNewEpics$ = this.apollo.mutate({ mutation, variables });
    return from(suggestNewEpics$).pipe(
      map((response: any) => response.data.suggestNewEpics)
    );
  }
}
