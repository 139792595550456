@if (!noVSCodeConnected) {
  <ngx-guided-tour></ngx-guided-tour>
  <ng-container *ngrxLet="selectedTemplate$ as template">
    <h1 id="generate-title" mat-dialog-title>
      <div class="inner-title-container">
        @if (template.type === 'Edit') {
          <div>Generate Code and Edit</div>
        }
        @if (
          template.type !== 'Command' &&
          template.type !== 'Edit'
          ) {
          <div
            >
            Generate Code
          </div>
        }
        @if (template.type === 'Command') {
          <div>Run Command</div>
        }
        <ng-container *ngrxLet="user$ as user">
          @if (generateMode === 'Github') {
            <div class="github-fields-container">
              @if (!(data.template?.starter)) {
                <mat-form-field class='github-field'>
                  <mat-label>GitHub Repo</mat-label>
                  <input type="text"
                    matInput
                    (keyup)="filterGithubRepos($event)"
                    [value]="githubRepo?.name"
                    [matAutocomplete]="githubAuto"
                    (input)="closeTreePanel()"
                    >
                    <mat-autocomplete #githubAuto="matAutocomplete" (optionSelected)="closeTreePanel()">
                      @if ((user?.githubOrg || (user?.activeOrgId === user?.userId))) {
                        @for (repo of githubRepos; track repo) {
                          <mat-option
                            (click)="selectGithubRepo(repo)"
                            [value]="repo.name"
                            >
                            {{ repo.name }}
                          </mat-option>
                        }
                        <mat-option class="github-link" (click)="getGithubRepos(user?.githubOrg)">
                          <i class="refresh-icon fa-regular fa-refresh"></i>Get Repos
                        </mat-option>
                      } @else {
                        <mat-option (click)="connectGithub()">
                          <i class="fa-2x fa-brands fa-github"></i> Choose organization
                        </mat-option>
                      }
                    </mat-autocomplete>
                  </mat-form-field>
                }
                @if (!(data.template?.starter)) {
                  <mat-form-field class="github-field">
                    <mat-label>GitHub Branch</mat-label>
                    <input type="text"
                      matInput
                      [value]="githubBranch"
                      [formControl]="branchControl"
                      [matAutocomplete]="auto"
                      (input)="closeTreePanel()"
                      >
                      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="closeTreePanel()">
                        @if (githubBranches) {
                          @for (option of githubBranches; track option) {
                            <mat-option [value]="option?.name">
                              {{option?.name}}
                            </mat-option>
                          }
                        }
                        <mat-option class="github-link" (click)="getGithubBranches()">
                          <i class="refresh-icon fa-regular fa-refresh"></i>Get Branches
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  }
                </div>
              }
            </ng-container>
            <mat-button-toggle-group (change)="switchTab($event, template)" [(value)]="generateMode">
              <mat-button-toggle value="Github"> <i class="fa-brands fa-square-github"></i>
                @if(data?.user?.userId === data?.user?.orgId) {
                  Personal
                } @else {
                  @if(data?.user?.orgType === 'Community') {
                    Razroo-Community
                  } @else {
                    {{ data?.user?.githubOrg }}
                  }
                }
                
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          </h1>
          <div mat-dialog-content>
            @if (data.template.starter && data.template.pathId.includes('angular')) {
              <div>
                <razroo-zeta-path-dropdown class="Search__path-dropdown" (getPaths)="getPaths()" (setPath)="setPathHandle($event)" [orgId]="orgId" [searchPath]="pathForStarterDropdown" [paths]="pathsDropdownItems" [pathsLoading]="pathsLoading"
                [vsCodeInstances]="vsCodeInstances" [userActiveProject]="userActiveProject" [iconOnly]="false" [communityFrameworksOnly]="true"></razroo-zeta-path-dropdown>
              </div>
            }
            <form [formGroup]="fileParamsFormGroup" class="step">
              <div class="GenerateCodeDialog__container">
                @if ((templateLoaded$ | ngrxPush)) {
                  <div class="path-branch">
                    <div class="params-scroller-container">
                      <div class="parameters">
                        @for (parameter of parameters; track trackInput(i, parameter); let i = $index) {
                          <div class="GenerateCode__param">
                            @if (fileParamsFormGroup?.controls[parameter?.name] && parameter?.paramType !== 'filePath') {
                              <mat-form-field
                                floatLabel="always"
                                class="dialog-form-field"
                                >
                                <ng-container *ngrxLet="vsCodeInstance$ as vsCodeInstance">
                                  <ng-container *ngrxLet="vsCodeParameters$ as vsCodeParameters">
                                    <mat-label>{{ parameter?.description }}</mat-label>
                                    <input
                                      matInput
                                      [type]="parameter?.inputType" razrooZetaDebounce [debounceTime]="300"
                                      (onEvent)="inputChanged($event, parameter, vsCodeParameters, template)"
                                      (change)="transformColorParams($event, parameter.type)"
                                      [formControlName]="parameter?.name"
                                      [readonly]="parameter?.disabled"
                                      [required]="parameter?.required"
                                      />
                                  </ng-container>
                                </ng-container>
                              </mat-form-field>
                            }
                            @if (parameter?.paramType !== 'filePath' && parameter?.name === 'name' && isSchema) {
                              <div>
                                <mat-accordion>
                                  <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                      <mat-panel-title>
                                        Schema Data
                                      </mat-panel-title>
                                      <mat-panel-description>
                                        <small>Choose data from you project schema, to be used in the generated code</small>
                                      </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div class="schema-tree">
                                      <razroo-zeta-schema-nested-tree [schemaValue]="projectSchemaValues" [schemaSearch]="nameParameterValue" (schemaOutput)="setSchemaValue($event)"></razroo-zeta-schema-nested-tree>
                                    </div>
                                  </mat-expansion-panel>
                                </mat-accordion>
                              </div>
                            }
                            <ng-container *ngrxLet="vsCodeInstance$ as vsCodeInstance">
                              <ng-container *ngrxLet="vsCodeParameters$ as vsCodeParameters">
                                <ng-container *ngrxLet="vsCodeDirectories$ as vsCodeDirectories">
                                  @if (parameter?.paramType === 'filePath' && template.type !== 'Command') {
                                    <razroo-zeta-file-path-input [githubRepo]="githubRepo" [githubBranch]="branchControl.value" [closePanel]="panelCloser"
                                      [generateMode]="generateMode" [parameter]="parameter" [parameters]="vsCodeInstance?.parameters" [template]="template" (logFolderClick)="logFolderClick($event)" (panelOpened)="treeOpened()"
                                    (inputChanged)="filePathInputChanged($event, parameter, vsCodeParameters, vsCodeInstance?.privateDirectories, template?.fileTree)"></razroo-zeta-file-path-input>
                                  }
                                </ng-container>
                              </ng-container>
                            </ng-container>
                            @if (parameter?.type == 'Toggle') {
                              <mat-form-field
                                appearance="none"
                                floatLabel="always"
                                class="dialog-form-field"
                                >
                                <mat-slide-toggle [formControlName]="parameter?.name" [required]="parameter?.required"
                                  >{{ parameter?.description }}</mat-slide-toggle
                                  >
                                  <textarea matInput hidden></textarea>
                                </mat-form-field>
                              }
                            </div>
                          }
                        </div>
                        <div>
                        </div>
                      </div>
                    </div>
                  } @else {
                    <div class="GenerateCodeDialog__loader">
                      <h3>Generating {{template?.title}}...</h3>
                      <div>
                        <mat-spinner color="accent" diameter="150"></mat-spinner>
                      </div>
                    </div>
                  }
                  <div class="GenerateCode__aggregate-container">
                    @if (template?.dependentSteps) {
                      <div>
                        <h3 class="GenerateCode__step-dependency-header">Step Dependencies</h3>
                        <ul class="GenerateCode__step-dependency-list">
                          @for (dependency of template?.dependentSteps; track dependency) {
                            <li>
                              <p>{{dependency?.stepId}}</p>
                            </li>
                          }
                        </ul>
                      </div>
                    }
                    @if (template?.stepper?.length > 1 && !template?.starter) {
                      <div class="GenerateCodeDialog__stepper-container">
                        <razroo-zeta-stepper-scroller
                        class="GenerateCodeDialog__stepper-scroller" [template]="template"></razroo-zeta-stepper-scroller>
                      </div>
                    }
                  </div>
                </div>
                @if (template.type === 'Command') {
                  <div>
                    <ng-container *ngrxLet="commandText$ as commandText">
                      @if (commandText) {
                        <div class="terminal-box">
                          <pre><code [highlight]="commandText | replaceCommandText : fileParamsFormGroup.value"></code></pre>
                        </div>
                      }
                    </ng-container>
                  </div>
                }
                <div class="action-buttons" align="end">
                  <button mat-flat-button [mat-dialog-close]>Cancel</button>
                  <button
                    mat-flat-button
                    id="generate-button"
                    class="generate-code-button"
                    [disabled]="!(templateLoaded$ | ngrxPush)"
                    (click)="generate({ type: template.type }, template)"
                    color="accent"
                    type="submit"
                    cdkFocusInitial
                    >
                    @if (template.type === 'Edit') {
                      <span>Generate and Edit</span>
                    }
                    @if (
                      template.type !== 'Command' &&
                      template.type !== 'Edit'
                      ) {
                      <span
                        >Generate</span
                        >
                      }
                      @if (template.type === 'Command') {
                        <span>Run</span>
                      }
                    </button>
                  </div>
                </form>
              </div>
            </ng-container>
          } @else {
            <div mat-dialog-content>
              <i>Connect to the Razroo VSCode extension to generate code to your local code editor</i>
            </div>
          }
          