import gql from 'graphql-tag';
import { TemplateFragment } from './template.fragments';

export const DeleteTemplate = gql`
  mutation DeleteTemplate(
      $orgId: String!,
      $templateType: DeleteTemplateType!
      $pathId: String!,
      $recipeId: String,
      $stepId: String
    ) {
    deleteTemplate(
      orgId: $orgId,
      pathId: $pathId,
      recipeId: $recipeId,
      stepId: $stepId,
      templateType: $templateType
    ) {
      ...templateFields
    }
  }
  ${TemplateFragment}
`;

export const AssignRecipeAsScaffold = gql`
  mutation AssignRecipeAsScaffold(
    $orgId: String!,
    $pathId: String!,
    $recipeId: String!,
    $scaffold: Boolean!
  ){
    assignRecipeAsScaffold(
      orgId: $orgId,
      pathId: $pathId,
      recipeId: $recipeId,
      scaffold: $scaffold
    ){
      ...templateFields
    }
  }
  ${TemplateFragment}
`

export const AssignStepAsStarter = gql`
  mutation AssignStepAsStarter(
    $userOrgId: String!
    $pathOrgId: String!
    $pathId: String!
    $recipeId: String!
    $stepId: String!
    $starter: Int
  ){
    assignStepAsStarter(
      userOrgId: $userOrgId
      pathOrgId: $pathOrgId
      pathId: $pathId
      recipeId: $recipeId
      stepId: $stepId
      starter: $starter
    ){
      ...templateFields
    }
  }
  ${TemplateFragment}
`

export const GenerateAITemplate = gql`
  mutation GenerateAiTemplate(
    $orgId: String!
    $userId: String!
    $pathId: String!
    $pathOrgId: String!
    $search: String!
    $componentType: String!
  ){
    generateAiTemplate(
      orgId: $orgId,
      userId: $userId,
      pathId: $pathId,
      pathOrgId: $pathOrgId,
      search: $search,
      componentType: $componentType
    ){
      ...templateFields
    }
  }
  ${TemplateFragment}
`;

export const AddDocumentationViaAiToStep = gql`
  mutation AddDocumentationViaAiToStep(
    $pathOrgId: String!, 
    $pathId: String!, 
    $recipeId: String!, 
    $stepId: String!, 
    $featureName: String!, 
    $featureType: String!, 
    $fileTree: [String]
  ){
    addDocumentationViaAiToStep(
      pathOrgId: $pathOrgId, 
      pathId: $pathId, 
      recipeId: $recipeId, 
      stepId: $stepId, 
      featureName: $featureName, 
      featureType: $featureType, 
      fileTree: $fileTree
    ){
      markdownConvertedToHtml
      markdown
    }
  }
`

export const ReassignTemplateOwner = gql`
  mutation reassignTemplateOwner(
    $userOrgId: String!,
    $userId: String!,
    $newUserId: String!,
    $pathOrgId: String!,
    $pathId: String!,
    $recipeId: String,
    $stepId: String
  ){
    reassignTemplateOwner(
      userOrgId: $userOrgId,
      userId: $userId,
      newUserId: $newUserId,
      pathOrgId: $pathOrgId,
      pathId: $pathId,
      recipeId: $recipeId,
      stepId: $stepId
    ){
      ...templateFields
    }
  }
  ${TemplateFragment}
`;

export const SaveDocumentation = gql`
  mutation saveDocumentation(
    $pathOrgId: String!, 
    $pathId: String!, 
    $recipeId: String!, 
    $stepId: String!, 
    $markdown: String!
  ){
    saveDocumentation(
      pathOrgId: $pathOrgId,
      pathId: $pathId,
      recipeId: $recipeId,
      stepId: $stepId,
      markdown: $markdown
    ){
      markdownConvertedToHtml
    }
  }
`;


