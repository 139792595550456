import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { catchError, concatMap, map, pluck, toArray } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import {
  AddDocumentationViaAiToStep,
  AssignRecipeAsScaffold,
  AssignStepAsStarter,
  CreateStep,
  DeleteTemplate,
  DownvoteTemplate,
  GenerateAITemplate,
  GetStarterFileTree,
  GetStepFileUploadUrl,
  GetTemplate,
  GetTemplateFileUrl,
  GetTemplateParams,
  GetTemplatePermissions,
  LikeTemplate,
  PortExistingCodeFileToNewStep,
  ReassignTemplateOwner,
  SaveDocumentation,
  UpdateStep,
} from '@razroo-zeta/data-graphql';
import { DeleteTemplateType, StepDependency, StepUpdateParams, TemplateInputParameter } from '@razroo-zeta/data-models';
import { DynamicStaticFilePath, FileTreeService, FlatFolderFile } from '@razroo-zeta/common-services';
import { replaceCurlyBrace } from '@codemorph/devkit';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  constructor(private apollo: Apollo, private fileTreeService: FileTreeService, private snackBar: MatSnackBar) {}

  getTemplate(
      orgId: string,
      pathId: string,
      recipeId: string,
      stepId?: string,
      networkOnly?: boolean,
      published?: boolean,
      displayDocumentationHtml?: boolean
    ) {
    const query = GetTemplate;
    const variables = {
      orgId,
      pathId,
      recipeId,
      stepId,
      published,
      stepper: true,
      displayDocumentationHtml
    };
    const template$ = networkOnly ? this.apollo.query({ query, variables, fetchPolicy: 'network-only'}) : this.apollo.query({ query, variables });
    return from(template$).pipe(pluck('data', 'template'));
  }
  getTemplateNetworkOnly(
      orgId: string,
      pathId: string,
      recipeId: string,
      stepId?: string
    ) {
    const query = GetTemplate;
    const variables = {
      orgId,
      pathId,
      recipeId,
      stepId
    };
    const template$ = this.apollo.query({ query, variables, fetchPolicy: 'network-only' });
    return from(template$).pipe(pluck('data', 'template'));
  }

  deleteTemplate(orgId: string, templateType: DeleteTemplateType, pathId: string,
    recipeId: string, stepId: string) {
      const mutation = DeleteTemplate;
      const variables = {
        orgId,
        pathId,
        recipeId,
        stepId,
        templateType
      };
      const deleteTemplate$ = this.apollo.mutate({ mutation, variables });
      return from(deleteTemplate$).pipe(map((deleteTemplate: any) => deleteTemplate.data.deleteTemplate));
  }

  getDependentSteps(
    orgId: string,
    pathId: string,
    dependentSteps: StepDependency[]
  ): Observable<any> {
    return dependentSteps ? from(dependentSteps).pipe(
      concatMap((dependencyStep: any) => {
        return this.getTemplate(dependencyStep.orgId, dependencyStep.pathId, dependencyStep.recipeId, dependencyStep?.stepId ? dependencyStep.stepId : dependencyStep.id).pipe(
          map((template: any) => {
            // update this so only one level deep
            const updatedFileTree = this.getStaticFileTreeFromParameters(template.fileTree, template.parameters);
            return this.fileTreeService.returnStepFileTreeFilesToGenerateFlatFolderStructure(updatedFileTree, template.parameters, dependencyStep)
          }),
          catchError((error: any) => {
            // const errorMessage = `Failed to get step dependency for recipe ${dependencyStep.recipeId} and step ${dependencyStep.stepId}: ${error.message}`;
            // console.log('step dependency errorMessage: ', errorMessage);
            // this.snackBar.open(errorMessage, '', {duration: 5000})
            return of([]);
          })
        )
      }),
      toArray(),
      map(data => data.reduce<FlatFolderFile[]>((acc, val) => acc.concat(val), []))
    ) : of([])
  }

  replaceParameters(stringToReplace: string, parameters: Record<string, string>): string {
    const replaced = stringToReplace.replace(/{([^}]+)}/g, (match, key) => {
      return Object.prototype.hasOwnProperty.call(parameters, key) ? parameters[key] : match;
    });
    return replaced.startsWith('/') ? replaced.slice(1) : replaced;
  }

  // will also add original path as dynamic path
  getStaticFileTreeFromParameters(fileTree: string[], templateParameters: TemplateInputParameter[]): DynamicStaticFilePath[] {
    const parametersForReplace: any = {};
    if(templateParameters) {
      for(const param of templateParameters) {
        parametersForReplace[(param as any)['name']] = param.defaultValue;
      }
    }
    
    const modifiedFileTree = fileTree?.map(filePath => {
      return {
        path: this.replaceParameters(filePath as string, parametersForReplace),
        dynamicPath: filePath
      }
    });
    
    return modifiedFileTree;
  }

  getRecipe(
      orgId: string,
      pathId: string,
      recipeId: string,
      published?: boolean,
      stepper = true
    ) {
    const query = GetTemplate;
    const variables = {
      orgId,
      pathId,
      recipeId,
      published,
      stepper
    };
    const template$ = this.apollo.query({ query, variables });
    return from(template$).pipe(pluck('data', 'template'));
  }

  determineIfExcessFilePathParameters(parameters: TemplateInputParameter[]) {
    const parametersArray = parameters ? parameters : [];
    const filePathParmeters = parametersArray.filter(parameter => parameter.paramType === 'filePath' )
    if(filePathParmeters.length > 3) {
      return true;
    }
    else {
      return false;
    }
  }

  updateStep(
    orgId: string,
    pathId: string,
    recipeId: string,
    stepId: string,
    stepUpdateParams: StepUpdateParams
    ) {
    const mutation = UpdateStep;
    const variables = {
      orgId,
      pathId,
      recipeId,
      stepId,
      stepUpdateParams
    }
    const updateStep$ = this.apollo.mutate({ mutation, variables });
    return from(updateStep$).pipe(pluck('data', 'updateStep'));
  }

  createStep(
    orgId: string,
    userId: string,
    pathId: string,
    recipeId: string,
    stepName: string,
    stepType: string,
    description?: string | null,
    parameters?: TemplateInputParameter[]
    ) {
    const createStepParams = {
      parameters
    };
    const mutation = CreateStep;
    const variables = {
      orgId,
      userId,
      pathId,
      recipeId,
      stepName,
      stepType,
      description,
      createStepParams
    }
    const newStep$ = this.apollo.mutate({ mutation, variables });
    return from(newStep$).pipe(pluck('data', 'createStep'));
  }

  // takes in path name and returns anticipated data
  getPathTemplate(
    orgId: string,
    pathId: string
    ): any {
    const query = GetTemplate;
    const variables = {
      orgId,
      pathId,
      stepper: true
    };

    const template$ = this.apollo.query({ query, variables });
    return from(template$).pipe(pluck('data', 'template'));
  }

  getTemplateParams(orgId: string, pathId: string, recipeId: string, stepId: string) {
    const query = GetTemplateParams;
    const variables = {
      orgId,
      pathId,
      recipeId,
      stepId
    };

    const template$ = this.apollo.query({ query, variables });
    return from(template$).pipe(pluck('data', 'template', 'parameters'));
  }

  getFileUrl(
    orgId: string,
    pathId: string,
    recipeId: string,
    stepId: string,
    fileName: string,
    ) {
    const query = GetTemplateFileUrl;
    const variables = {
      stepId,
      fileName,
      orgId,
      pathId,
      recipeId
    };
    const templateUrl$ = this.apollo.query({ query, variables });
    return from(templateUrl$).pipe(pluck('data', 'getS3TemplateFile'));
  }

  likeTemplate(userId: string, templateOrgId: string, pathId: string, recipeId: string, stepId: string){
    const mutation = LikeTemplate;
    const variables = {
      userId,
      templateOrgId,
      pathId,
      recipeId,
      stepId
    };

    const template$ = this.apollo.mutate({ mutation, variables });
    return from(template$).pipe(pluck('data', 'likeTemplate'));
  }

  downvoteTemplate(userId: string, templateOrgId: string, pathId: string, recipeId: string, stepId: string){
    const mutation = DownvoteTemplate;
    const variables = {
      userId,
      templateOrgId,
      pathId,
      recipeId,
      stepId
    };

    const template$ = this.apollo.mutate({ mutation, variables });
    return from(template$).pipe(pluck('data', 'downvoteTemplate'));
  }

  getTemplatePermissions(
    orgId: string,
    pathId: string,
    recipeId?: string,
    stepId?: string,
    ) {
    const query = GetTemplatePermissions;
    const variables = {
      orgId,
      pathId,
      recipeId,
      stepId
    };
    const templatePermissions = this.apollo.query({ query, variables, fetchPolicy: 'no-cache' });
    return from(templatePermissions).pipe(pluck('data', 'getTemplatePermissions'));
  }

  assignRecipeAsScaffold(
    orgId: string,
    pathId: string,
    recipeId: string,
    scaffold: boolean
    ){
      const mutation = AssignRecipeAsScaffold;
      const variables = {
        orgId,
        pathId,
        recipeId,
        scaffold
      };
      const assignRecipeAsScaffold$ = this.apollo.mutate({ mutation, variables});
      return from(assignRecipeAsScaffold$).pipe(pluck('data', 'assignRecipeAsScaffold'));
  }
  
  assignStepAsStarter(
    userOrgId: string,
    pathOrgId: string,
    pathId: string,
    recipeId: string,
    stepId: string,
    starter: number
    ){
      const mutation = AssignStepAsStarter;
      const variables = {
        userOrgId,
        pathOrgId,
        pathId,
        recipeId,
        stepId,
        starter
      };
      const assignStepAsStarter$ = this.apollo.mutate({ mutation, variables});
      return from(assignStepAsStarter$).pipe(pluck('data', 'assignStepAsStarter'));
  }

  generateAiTemplate(
    orgId: string,
    userId: string,
    pathId: string,
    pathOrgId: string,
    search: string,
    componentType: string
    ){
      const mutation = GenerateAITemplate;
      const variables = {
        orgId,
        userId,
        pathId,
        pathOrgId,
        search,
        componentType
      };
      const generateAiTemplate$ = this.apollo.mutate({ mutation, variables});
      return from(generateAiTemplate$).pipe(pluck('data', 'generateAiTemplate'));
  }

  getStepFileUploadUrl(
    userOrgId: string,
    pathOrgId: string,
    pathId: string,
    recipeId: string,
    stepId: string,
    fullFilePath?: string,
    isNewFile?: boolean
    ){
      const query = GetStepFileUploadUrl;
      const variables = {
        userOrgId,
        pathOrgId,
        pathId,
        recipeId,
        stepId,
        fullFilePath,
        isNewFile
      };
      const getStepFileUploadUrl$ = this.apollo.query({ query, variables});
      return from(getStepFileUploadUrl$).pipe(map(({data}) => (data as any).getStepFileUploadUrl));
  }

  addDocumentationViaAiToStep(
    pathOrgId: string, 
    pathId: string, 
    recipeId: string, 
    stepId: string, 
    featureName: string, 
    featureType: string, 
    fileTree?: string[]
  ){
      const mutation = AddDocumentationViaAiToStep;
      const variables = {
        pathOrgId, 
        pathId, 
        recipeId, 
        stepId, 
        featureName, 
        featureType, 
        fileTree
      };
      const addDocumentationViaAiToStep$ = this.apollo.mutate({ mutation, variables});
      return from(addDocumentationViaAiToStep$).pipe(map(({data}) => (data as any).addDocumentationViaAiToStep));
  }

  reassignTemplateOwner(
    userOrgId: string,
    userId: string,
    newUserId: string,
    pathOrgId: string,
    pathId: string,
    recipeId?: string,
    stepId?: string){
    const mutation = ReassignTemplateOwner;
    let variables = {
      userOrgId,
      userId,
      newUserId,
      pathOrgId,
      pathId,
      recipeId,
      stepId
    }
    const reassignTemplateOwner$ = this.apollo.mutate({mutation, variables});
    return from(reassignTemplateOwner$).pipe(map(({data}) => (data as any).reassignTemplateOwner))
  }

  portExistingCodeFileToNewStep(
    userOrgId: string,
    fromPathOrgId: string,
    fromPathId: string,
    fromRecipeId: string,
    fromStepId: string,
    toPathOrgId: string,
    toPathId: string,
    toRecipeId: string,
    toStepId: string,
    staticFilePath: string,
    dynamicFilePath?: string,
    contentType?: string
  ) {
    const mutation = PortExistingCodeFileToNewStep;
    const variables = {
      userOrgId,
      fromPathOrgId,
      fromPathId,
      fromRecipeId,
      fromStepId,
      toPathOrgId,
      toPathId,
      toRecipeId,
      toStepId,
      dynamicFilePath,
      staticFilePath,
      contentType
    };
    const portExistingCodeFileToNewStep$ = this.apollo.mutate({ mutation, variables });
    return from(portExistingCodeFileToNewStep$).pipe(map(({ data }) => (data as any).portExistingCodeFileToNewStep));
  }

  saveDocumentation(
    pathOrgId: string, 
    pathId: string, 
    recipeId: string, 
    stepId: string, 
    markdown: string){
    const mutation = SaveDocumentation;
    const variables = {
      pathOrgId, 
      pathId, 
      recipeId, 
      stepId, 
      markdown
    }
    const saveDocumentation$ = this.apollo.mutate({mutation, variables});
    return from(saveDocumentation$).pipe(map(({data}) => (data as any).saveDocumentation))
  }

  getStarterFileTree(
    pathOrgId: string, 
    pathId: string){
    const mutation = GetStarterFileTree;
    const variables = {
      pathOrgId, 
      pathId, 
    }
    const getStarterFileTree$ = this.apollo.mutate({mutation, variables});
    return from(getStarterFileTree$).pipe(map(({data}) => (data as any).getStarterFileTree))
  }

}
