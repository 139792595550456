/*
* This component contains two different ways of implementing 
* rust/wasm, both have been commented out pending devops
* changes to accommodate rust/wasm. 
* 
* Sections marked: DYL are by Dovid Levine and that method involves
* saving wasm in a variable after it is loaded so that the rust functions
* can thereafter be accessed directly.
*
* Sections marked: LH are by LIRON HAZAN - see https://github.com/LironHazan/angular-rust-wasm/blob/master/src/app/the-band/the-band.component.ts
*/


import { Component, OnInit } from '@angular/core';
import {defer, Observable, of} from 'rxjs';

@Component({
  selector: 'razroo-zeta-rust-wasm-testing',
  templateUrl: './rust-wasm-testing.component.html',
  styleUrls: ['./rust-wasm-testing.component.scss'],
})
export class RustWasmTestingComponent implements OnInit {
  constructor() {}

  // /******* DYL:
    wasm: {
      add(n1: number, n2: number): number;
    };

    private async getWasm() {
      const wasmImp = await import('../../../../../wasm/pkg/razroo_wasm');
      this.wasm = wasmImp;
    }

    getAnswer() {
      let answer = this.wasm.add(21, 21)
      console.log('answer: ', answer)
    }
  // *********/
  
  /****** LH:

  answer$: Observable<any> = of();

  *******/
  ngOnInit(): void {
    // /***** DYL: 
      this.getWasm().then(() => this.getAnswer())
    // *****/
    /****** LH: 
      this.answer$ = this.getTheNumber$(21, 21);
      this.answer$.subscribe(res => console.log('answer: ', res))
    ******/
  }

  /******** LH:

  private async getTheNumber(n1: number, n2: number): Promise<any> {
    const wasm = await import('../../../../../wasm/pkg/razroo_wasm');
    return wasm.add(n1, n2);
  }

  getTheNumber$(n1: number, n2: number ): Observable<number> {
    return defer(() => this.getTheNumber(n1, n2));
  }

  *********/
}
