import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TEMPLATES_FEATURE_KEY,
  State,
  templatesAdapter,
} from './templates.reducer';

// Lookup the 'Templates' feature state managed by NgRx
export const getTemplatesState = createFeatureSelector<State>(TEMPLATES_FEATURE_KEY);

const { selectAll, selectEntities } = templatesAdapter.getSelectors();

export const getRelevantQuestionsAiLoaded = createSelector(
  getTemplatesState,
  (state: State) => state.relevantQuestionsAiLoaded
);

export const getInstructionalContentAiLoaded = createSelector(
  getTemplatesState,
  (state: State) => state.instructionalContentAiLoaded
);

export const getCommandText = createSelector(
  getTemplatesState,
  (state: State) => state.commandText
);

export const getTemplatesLoaded = createSelector(
  getTemplatesState,
  (state: State) => state.loaded
);

export const getTemplatesError = createSelector(
  getTemplatesState,
  (state: State) => state.error
);

export const getTemplatesRecipeIdHoveredOver = createSelector(
  getTemplatesState,
  (state: State) => state.recipeIdHoveredOver
);

export const getAllTemplates = createSelector(
  getTemplatesState,
  (state: State) => selectAll(state)
);

export const getTemplatesEntities = createSelector(
  getTemplatesState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getTemplatesState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getTemplatesEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getSelectedStepper = createSelector(
  getSelected,
  (selected) => (selected ? selected.stepper : undefined)
);

export const getNextRecipe = createSelector(
  getTemplatesEntities,
  getSelected,
  (entities, selected) => selected ? entities[selected.next as string] : undefined
);

export const getSelectedInstructionalContent = createSelector(
  getSelected,
  (selected) => (selected ? selected.instructionalContent : undefined)
);

export const getStepper = createSelector(
  getTemplatesState,
  (state: State) => state.stepper
);

export const getAggregate = createSelector(
  getTemplatesState,
  (state: State) => state.aggregate
);

export const getRelevantQuestions = createSelector(
  getSelected,
  (selected) => selected ? JSON.parse(selected.relevantQuestions) : undefined
);

export const getTemplateUpdates = createSelector(
  getSelected,
  (selected) => selected ? JSON.parse(selected.updates as string) : undefined
);

export const getPosition = createSelector(
  getSelected,
  (selected) => selected ? selected.position : undefined
);

export const getTemplateId = createSelector(
  getSelected,
  (selected) => selected ? selected.id : undefined
);
