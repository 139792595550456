import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { DeveloperOrBetaUserGuard, UserProfileLoadedGuard, AuthRedirectGuard } from '@razroo-zeta/data-guards'
import { DummyComponent } from '@razroo-zeta/ui/common';
import { RustWasmTestingComponent } from "@razroo-zeta/rust-wasm";
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthRedirectGuard],
    canLoad: [AuthRedirectGuard],
    loadChildren: () => import('@razroo-zeta/landing-page').then((m) => m.LandingPageModule),
  },
  {
    path: 'home',
    canLoad: [UserProfileLoadedGuard],
    canActivate: [UserProfileLoadedGuard],
    loadChildren: () => import('@razroo-zeta/home').then((m) => m.HomeModule),
  },
  {
    path: 'finder',
    loadChildren: () => import('@razroo-zeta/razroo-path').then((m) => m.RazrooPathModule),
    canLoad: [UserProfileLoadedGuard],
  },
  {
    path: 'tickets',
    loadChildren: () => import('@razroo-zeta/tickets').then((m) => m.TicketsModule),
    canLoad: [UserProfileLoadedGuard],
  },
  {
    path: 'backlog',
    loadChildren: () => import('@razroo-zeta/tickets-backlog').then((m) => m.TicketsBacklogModule),
    canLoad: [UserProfileLoadedGuard],
  },
  {
    path: 'kanban',
    loadChildren: () => import('@razroo-zeta/kanban').then((m) => m.KanbanModule),
    canLoad: [UserProfileLoadedGuard],
  },
  {
    path: 'add-slack-app',
    loadChildren: () => import('@razroo-zeta/slack').then((m) => m.SlackModule),
    canLoad: [UserProfileLoadedGuard]
  },
  {
    path: 'chat',
    loadChildren: () => import('@razroo-zeta/chat').then((m) => m.ChatModule),
    canLoad: [UserProfileLoadedGuard],
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('@razroo-zeta/projects').then((m) => m.ProjectsModule),
    canLoad: [AuthGuard, DeveloperOrBetaUserGuard]
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('@razroo-zeta/settings').then((m) => m.SettingsModule),
    pathMatch: 'prefix',
    canLoad: [UserProfileLoadedGuard, AuthGuard]
  },

  {
    path: 'sessions',
    loadChildren: () =>
      import('@razroo-zeta/team-sessions').then((m) => m.TeamSessionsModule),
    canLoad: [AuthGuard, DeveloperOrBetaUserGuard]
  },
  {
    path: 'library',
    loadChildren: () =>
      import('@razroo-zeta/library-module').then((m) => m.LibraryModuleModule)
  },
  {
    path: 'book/:id',
    loadChildren: () => import('@razroo-zeta/book').then((m) => m.BookModule)
  },
  {
    path: 'book/:id/:chapter',
    loadChildren: () => import('@razroo-zeta/book').then((m) => m.BookModule)
  },
  {
    path: 'book/:id/:chapter/:searchText',
    loadChildren: () => import('@razroo-zeta/book').then((m) => m.BookModule)
  },
  // {
  //   path: 'certifications',
  //   loadChildren: () =>
  //     import('@razroo-zeta/certifications').then((m) => m.CertificationsModule),
  //   canLoad: [AuthGuard]
  // },
  {
    path: 'advertising',
    loadChildren: () =>
      import('@razroo-zeta/billing').then((m) => m.BillingModule),
    canLoad: [AuthGuard]
  },
  // {
  //   path: 'analytics',
  //   loadChildren: () =>
  //     import('@razroo-zeta/analytics').then((m) => m.AnalyticsModule),
  //     canLoad: [AuthGuard]
  // },
  {
    path: 'pricing',
    loadChildren: () =>
      import('@razroo-zeta/pricing').then((m) => m.PricingModule),
      canLoad: [AuthGuard]
  },
  // {
  //   path: 'billing',
  //   loadChildren: () =>
  //     import('@razroo-zeta/stripe').then((m) => m.StripeModule),
  //   canLoad: [ AuthGuard ],
  //   canActivate: [ OrgAdminManagerGuard]
  // },
  {
    path: 'accept-invitation',
    canLoad: [UserProfileLoadedGuard],
    loadChildren: () =>
      import('@razroo-zeta/accept-invite').then((m) => m.AcceptInviteModule)
  },
  {
    path: 'legal',
    loadChildren: () =>
      import('@razroo-zeta/legal').then((m) => m.LegalModule),
  },
  {
    path: 'datasets',
    canLoad: [UserProfileLoadedGuard],
    loadChildren: () =>
      import('@razroo-zeta/marketplace').then((m) => m.MarketplaceModule)
  },
  {
    path: ':orgId/:pathId/:batchId/:recipeId/:stepId',
    canLoad: [UserProfileLoadedGuard],
    loadChildren: () => import('@razroo-zeta/home').then((m) => m.HomeModule),
  },
  {
    path: ':orgId/:pathId/:recipeId/:stepId',
    canLoad: [UserProfileLoadedGuard],
    loadChildren: () => import('@razroo-zeta/home').then((m) => m.HomeModule),
  },
  {
    path: ':orgId/:pathId/:recipeId',
    canLoad: [UserProfileLoadedGuard],
    loadChildren: () => import('@razroo-zeta/home').then((m) => m.HomeModule),
  },
  {
    path: ':orgId/:pathId',
    canLoad: [UserProfileLoadedGuard],
    loadChildren: () => import('@razroo-zeta/home').then((m) => m.HomeModule),
  },
  {
    path: 'wasm-test',
    component: RustWasmTestingComponent
  },
  {
    path: 'redirect',
    component: DummyComponent
  },
  {
    path: '**',
    loadChildren: () =>
      import('@razroo-zeta/not-found-page').then((m) => m.NotFoundPageModule),
    canLoad: [UserProfileLoadedGuard],
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
