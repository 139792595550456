import { LatestStep } from '../../../../data-models/src/lib/user/user.interface';
import { ExecuteCodeDialogComponent } from '../../../../common/ui/src/lib/execute-code-dialog/execute-code-dialog.component';
import { GenerateCodeDialogComponent } from '../../../../ui/common/src/lib/generate-code-dialog/generate-code-dialog.component';
import { Injectable } from '@angular/core';
import {
  GetGenerateCode,
  GenerateVsCodeDownloadCode,
  GetCodeGenerationHistory,
  NextStepRecommendation
} from '@razroo-zeta/data-graphql';
import { EMPTY, from } from 'rxjs';
import { catchError, first, pluck, take } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { ActiveProject, COMMUNITY, GenerateCodeParameters, GithubRepo, OrganizationUser, ProjectParams, RecommendationsStep, Template, VsCodeInstance } from '@razroo-zeta/data-models';
import { MatDialog } from '@angular/material/dialog';
import { GenerateCodeFacade, OrganizationsFacade } from '@razroo-zeta/data-access/organizations';
import { VersioningService } from '@razroo-zeta/common-services';
import { ConfirmGenerationDialogComponent } from '../../../../ui/common/src/lib/confirm-generation-dialog/confirm-generation-dialog.component';
import { environment } from 'apps/zeta-frontend/src/environments/environment';
import { GithubService } from '../github/github.service';
import { replaceCurlyBrace } from '@codemorph/devkit';
import { kebabCase } from 'lodash';
import { GithubSubscriptionService } from '../github/github-subscription/github-subscription.service';

@Injectable({
  providedIn: 'root'
})
export class GenerateCodeService {
  constructor(private apollo: Apollo, private versioningService: VersioningService, 
    private githubService: GithubService, 
    private dialog: MatDialog, 
    private generateCodeFacade: GenerateCodeFacade,
    private githubSubscriptionService: GithubSubscriptionService,
    private organizationsFacade: OrganizationsFacade
  ) {}
  
  getCodeGenerationHistory(orgId: string, page = 1, size = 20){
    const query = GetCodeGenerationHistory;
    const variables = {
      orgId,
      page,
      size
    };
    const codeGenHistory$ = this.apollo.query({ query, variables });
    return from(codeGenHistory$).pipe(pluck('data', 'getCodeGenerationHistory'));
  }

  getGenerateCode(generateCodeParameters: GenerateCodeParameters) {
    const query = GetGenerateCode;
    const variables = {
      generateCodeParameters,
    };

    const generateCode$ = this.apollo.query({ query, variables });
    return from(generateCode$).pipe(pluck('data', 'generateCode'));
  }

  generateVsCodeDownloadCode(
    generateVsCodeDownloadCodeParameters: GenerateCodeParameters
  ) {
    const mutation = GenerateVsCodeDownloadCode;
    const variables = {
      generateVsCodeDownloadCodeParameters,
    };

    const generateVsCodeDownloadCode$ = this.apollo.mutate({
      mutation,
      variables,
    });
    return from(generateVsCodeDownloadCode$).pipe(
      pluck('data', 'generateVsCodeDownloadCode')
    );
  }

  toKebabCase(str: string) {
    return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  }

  createGenerateCodeParameters(result: any): any {
    const generateCodeParameters: GenerateCodeParameters = {
      parameters: result.params as any,
      vsCodeInstanceId: result.vsCodeInstanceId,
      // userOrgId + userId are set in the generate-code effect
      userOrgId: '',
      userId: '',
      projectName: '',
      projectParams: result.projectParams,
      pathOrgId: result.template.orgId,
      recipeId: result.template.recipeId,
      pathId: result.template.pathId,
      stepId: result.template.id,
      orgType: result.orgType
    };

    if (result.params.name) {
      result.selector = `${result.selector}-${this.toKebabCase(
        result.params.name
      )}`;
    }
    generateCodeParameters['parameters'] = JSON.stringify(result.params);

    return generateCodeParameters;
  }

  replaceProjectParams(parameters: any) {
    for (const key in parameters) {
      if(parameters[key]) {
        parameters[key] = replaceCurlyBrace(parameters, parameters[key]);
      }
    } 
    return parameters;
  }

  createParametersForGithubInput(template: Template, user: OrganizationUser, githubRepo: GithubRepo, parameters?: any, githubBranch?: string, githubRepoName?: string) {
    const projectParams = {
      orgId: user.activeProject?.orgId,
      pathId: (githubRepo && githubRepo.name) ? githubRepo.name : githubRepoName
    } as ProjectParams;

    let githubRepoOwner = '';
    if(user.githubOrg && user.orgType !== 'Community') {
      githubRepoOwner = user.githubOrg;
    } else if(githubRepo && githubRepo.owner && githubRepo.owner.login) {
      githubRepoOwner = githubRepo.owner.login
    }

    const generateCodeForGithubInput = {
      repoName: (githubRepo && githubRepo.name) ? githubRepo.name : githubRepoName,
      githubRepoOwner,
      branchName: githubBranch ? githubBranch : '',
      defaultBranch: githubRepo ? githubRepo.default_branch : 'main',
      userOrgId: user.orgId,
      userId: user.userId,
      starter: template.starter ? template.starter : false,
      pathOrgId: template.orgId,
      pathId: template.pathId,
      recipeId: template.recipeId,
      stepId: template.id,
      batchId: template.batchId ? template.batchId : template.executionId,
      parameters: parameters && typeof parameters === 'string' ? JSON.stringify(parameters) : parameters,
      projectParams: projectParams,
      orgType: user.orgType
    }

    return generateCodeForGithubInput;
  }

  executeCode(user: OrganizationUser, template: Template) {
    const dialogRef = this.dialog.open(ExecuteCodeDialogComponent, {
      panelClass: 'large-width',
      data: {
        template,
        user
      }
    });

    dialogRef
      .afterClosed()
      .pipe(take(1), catchError(() => EMPTY))
      .subscribe((result) => {
        if(result) {
          const githubInputParameters = this.createParametersForGithubInput(template, user, result.githubRepo, undefined, template.executionId, result.githubRepoName);
          const generateCodeSuccess = {
            ...template,
            template: template,
            id: template.id
          }

          // orgId is undefined here. Will look into
          const projectParams = {
            orgId: undefined as any,
            pathId: result.githubRepo && result.githubRepo.name
          } as ProjectParams;
          console.log('githubInputParameters: ', githubInputParameters)

          this.githubService.createJwtFromGenerateCodeForGithubInput(githubInputParameters).pipe(first()).subscribe((jwtTokenResponse: any) => {
            this.generateCodeFacade.loadGenerateCodeSuccess([generateCodeSuccess] as any, template.stepper, template, user.userId, user.orgId, result.vsCodeInstances, 'github', projectParams, result.params, user, result.githubRepo);
            const jwtToken = jwtTokenResponse.jwtToken;
            const githubPortalUrl = environment.githubPortalUrl;
            const url = `${githubPortalUrl}/api/auth/login?returnTo=/execute-code/${jwtToken}`;
            window.open(url, '_blank');
          });
        }
      });
  }

  generateCodeDialog(user: OrganizationUser, template: Template, userId: string, userOrgId: string, githubOrg?: string, activeProject?: ActiveProject | undefined, latestStep?: LatestStep, newWorkspace?: boolean) {
    const dialogRef = this.dialog.open(GenerateCodeDialogComponent, {
      panelClass: 'large-width',
      data: {
        currentSearchText: template.id,
        template: {...template},
        user: user,
        userId: userId,
        userOrgId: userOrgId,
        githubOrg: githubOrg,
        activeProject: activeProject,
        latestStep: latestStep,
        githubRepos: user.githubRepos
      },
    });
    // close it
    dialogRef
      .afterClosed()
      .pipe(take(1), catchError(() => EMPTY))
      .subscribe((result) => {
        if(result) {
          result.params = this.replaceProjectParams(result.params);
          // replace project params 
          if(result.generateMode && result.generateMode === 'Github') {
            const projectParams = {
              orgId: activeProject?.orgId,
              pathId: result.githubRepo && result.githubRepo.name
            } as ProjectParams;
            let githubRepoOwner = '';
            //if --> personal github <-- keep repoOwner empty and github-frontend will use user github account
            if(githubOrg && user.orgType !== 'Community') {
              githubRepoOwner = githubOrg;
            } else if(result.githubRepo && result.githubRepo.owner && result.githubRepo.owner.login && user.orgType !== 'Community') {
              githubRepoOwner = result.githubRepo.owner.login;
            } else if(user.orgType == 'Community'){
              githubRepoOwner = 'Razroo-Community';
            }
            const generateCodeForGithubInput = {
              repoName: result.githubRepo ? result.githubRepo.name : '',
              githubRepoOwner,
              branchName: result.githubBranch,
              defaultBranch: result.githubRepo ? result.githubRepo.default_branch : '',
              userOrgId: userOrgId,
              userId: userId,
              starter: result.template.starter,
              pathOrgId: result.template.orgId,
              pathId: result.template.pathId,
              recipeId: result.template.recipeId,
              stepId: result.template.id,
              parameters: JSON.stringify(result.params),
              projectParams: projectParams,
              orgType: user.orgType
            }

            const generateCodeSuccess = {
              ...template,
              template: result.template,
              id: result.template.id
            }
            
            this.githubService.createJwtFromGenerateCodeForGithubInput(generateCodeForGithubInput).pipe(first()).subscribe((jwtTokenResponse: any) => {
              this.generateCodeFacade.loadGenerateCodeSuccess([generateCodeSuccess] as any, result.template.stepper, result.template, userId, userOrgId, result.vsCodeInstances, 'github', projectParams, result.params, user, result.githubRepo, newWorkspace);
              const jwtToken = jwtTokenResponse.jwtToken;
              const githubPortalUrl = environment.githubPortalUrl;
              const url = `${githubPortalUrl}/api/auth/login?returnTo=/generate-code/${jwtToken}`;
              window.open(url, '_blank');
            });
          }
        }
      });
  }

  confirmGenerateDialog(template: Template, savedVariables: any, userId: string) {
    const dialogRef = this.dialog.open(ConfirmGenerationDialogComponent, {
      panelClass: 'large-width', 
      data: {
        variables: savedVariables,
        template,
        userId
      },
    });
    // close it
    dialogRef
      .afterClosed()
      .pipe(take(1), catchError(() => EMPTY))
      .subscribe((result) => {
        if(result) {
          const activeTemplate = template;
          const generateCodeParameters = this.createGenerateCodeParameters({params: {...savedVariables, selector: result.vsCodeInstance.packageJsonParams?.name}, template, type: 'Generate', vsCodeInstanceId: result.vsCodeInstanceId});
          this.generateCodeFacade.generateCode(generateCodeParameters, activeTemplate.stepper, activeTemplate, result.vsCodeInstances);
        }
      });
  }

  createNewActiveProjectAndReposObj(action: {template: Template, orgId?: string, user?: OrganizationUser, projectParams?: ProjectParams, githubRepo?: GithubRepo}, 
    name: string) {
    let githubRepo = action.githubRepo;
    name = (githubRepo && githubRepo.name) ? githubRepo.name : name;
    githubRepo = githubRepo ? githubRepo : {name} as any;
    const orgId = action.orgId;
    const languageVersion = '1.0.0';
    const coreProjectPathId = kebabCase(name);

    let githubReposUpdated: GithubRepo[]; 
    if(action.user && action.user.githubRepos?.length) {
      //only add repo if it doesn't already exist
      if(!action.user.githubRepos.find(repo => repo.name === coreProjectPathId)){
        githubReposUpdated = [
          {...(githubRepo as GithubRepo)},
          ...action.user.githubRepos,
        ]
      } else githubReposUpdated = [...action.user.githubRepos]
    } else githubReposUpdated = [{...(githubRepo as GithubRepo)}];
    // down the line will get from package json for starter of path generated from
    const activeProject = {
      orgId: orgId,
      id: `${coreProjectPathId}-${languageVersion}`,
      languageVersion: languageVersion,
      baseCommunityPath: action.template.pathId,
      project: true,
      title: name.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    } as ActiveProject;

    return {activeProject, githubReposUpdated}
  }

  getGithubRepos(userId: string, userOrgId: string, githubOrg: string) {
    this.githubSubscriptionService.sendGithubReposDataSub(userId).pipe(first()).subscribe((data: {userId: string, githubRepos: any} | any) => {
      this.organizationsFacade.updateOrganizationUser(userOrgId, userId, data.githubRepos, data.githubUserId);
    });
    const githubPortalUrl = environment.githubPortalUrl;
    let url = '';
    if(userId === userOrgId) {
      // personal don't send through gitHubOrg
      url = `${githubPortalUrl}/api/auth/login?returnTo=/repos/${userId}`;
    } else {
      // organization send through gitHubOrg
      url = `${githubPortalUrl}/api/auth/login?returnTo=/repos/${userId}_${githubOrg}`;
    }
    
    const width = 800;
    const height = 800;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
    window.open(url, "myWindow", `width=${width},height=${height},left=${left},top=${top}`);
  }

  createGenerateCodeParametersForTesting(template: Template, userId: string, orgId: string, defaultVSCodeStarter: VsCodeInstance, parameters: any, testType?: {runUnitTests?: true, runIntegrationTests?: true, runPreviewGeneration?: true}): GenerateCodeParameters {
    if(parameters.projectName && defaultVSCodeStarter.packageJsonParams) {
      parameters.projectName = defaultVSCodeStarter.packageJsonParams.name
    }
    const vsCodeInstanceId = defaultVSCodeStarter && defaultVSCodeStarter.vsCodeInstanceId;
    return {
      parameters: JSON.stringify(parameters),
      // we are going to need the vsCodeInstanceId
      vsCodeInstanceId: vsCodeInstanceId,
      userOrgId: orgId,
      userId: userId,
      pathOrgId: template.orgId,
      projectName: '',
      recipeId: template.recipeId,
      pathId: template.pathId,
      runUnitTests: testType?.runUnitTests ? true : undefined,
      runIntegrationTests: testType?.runIntegrationTests ? true : undefined,
      runPreviewGeneration: testType?.runPreviewGeneration ? true : undefined,
      stepId: template.id,
    };
  }

  vsCodeDefaultStarter(vsCodeAuthInfo: {vsCodeInstances: VsCodeInstance[]}, template: Template) {
    let corePathId = template.corePathId; 
    if(template.baseCommunityPath) {
      const { name } = this.versioningService.getVersionAndNameString(template.baseCommunityPath);
      corePathId = name;
    }
    return vsCodeAuthInfo ? vsCodeAuthInfo.vsCodeInstances.find(vsCodeInstance => {
      return vsCodeInstance.packageJsonParams && vsCodeInstance.packageJsonParams.name === `razroo-${corePathId}-starter`
    }) : false
  }

  turnParametersIntoCodeGenerationSyntax(parameters: any[]): any {
    const paramObject:any = {};
    parameters.forEach(param => {
      paramObject[param.name] = param.defaultValue;
    });

    return paramObject
  }

  nextStepRecommendation(userOrgId: string, recommendationsStep?: RecommendationsStep, projectName?: String){
    const query = NextStepRecommendation;
    const variables = {
      userOrgId,
      recommendationsStep,
      projectName
    }
    const nextStepRecommendation$ = this.apollo.query({query, variables, fetchPolicy: 'network-only'});
    return from(nextStepRecommendation$).pipe(pluck('data', 'nextStepRecommendation'));
  }
}
