import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'apps/zeta-frontend/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PricesService {
  constructor(private http: HttpClient) {}

  url: string = 'https://api.stripe.com/v1/prices';

  sendRequest() {
    const headers = new HttpHeaders().set(
      'Authorization',
      environment.stripePricesToken
    );

    return this.http.get(this.url, { headers: headers });
  }
}
