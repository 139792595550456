import { DataAccessTicketWorkspacesModule } from '../../ticket-workspaces/src/lib/data-access-ticket-workspaces.module';
import { DataAccessRecipesModule } from '../../recipes/src/lib/data-access-recipes.module';
import { DataAccessCodeChatModule } from '../../code-chat/src/lib/data-access-code-chat.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgModule, TransferState, makeStateKey } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionReducer, Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../../../apps/zeta-frontend/src/environments/environment';
import { DataAccessOrganizationsModule } from '@razroo-zeta/data-access/organizations';
import { DataAccessSearchModule } from '@razroo-zeta/data-access/search';
import { DataAccessBooksModule } from '@razroo-zeta/data-access/books';
import { DataAccessCommentsStoreModule } from 'libs/data-access/comments-store/src';
import { DataAccessPathModule } from 'libs/data-access/path/src'
import * as fromUser from './+state/user/user.reducer';
import { UserEffects } from './+state/user/user.effects';
import { UserFacade } from './+state/user/user.facade';
import * as fromTemplates from './+state/templates/templates.reducer';
import { TemplatesEffects } from './+state/templates/templates.effects';
import { TemplatesFacade } from './+state/templates/templates.facade';
import { DataAccessGitInfoModule } from 'libs/data-access/git-info/src';
import { DataAccessPathCmsModule } from '@razroo-zeta/data-access/path-cms';
import { DataAccessBillingModule } from '@razroo-zeta/data-access/billing';
import { DataAccessVscodeModule } from '@razroo-zeta/data-access/vscode';
import { DataAccessCodeGenerationHistoryModule } from '@razroo-zeta/data-access/code-generation-history';
import { DataAccessTicketsModule } from 'libs/data-access/tickets/src';

// make sure you export for AoT
export function stateSetter(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state: any, action: any) {
      if (action.type === 'SET_ROOT_STATE') {
          return action.payload;
      }
      return reducer(state, action);
  };
}


export const NGRX_STATE = makeStateKey('NGRX_STATE');

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [stateSetter] : [stateSetter],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    MatSnackBarModule,
    DataAccessOrganizationsModule,
    DataAccessSearchModule,
    DataAccessBooksModule,
    DataAccessGitInfoModule,
    DataAccessCommentsStoreModule,
    DataAccessPathModule,
    DataAccessPathCmsModule,
    DataAccessBillingModule,
    DataAccessVscodeModule,
    DataAccessCodeChatModule,
    DataAccessCodeGenerationHistoryModule,
    DataAccessRecipesModule,
    DataAccessTicketsModule,
    DataAccessTicketWorkspacesModule,
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreModule.forFeature(fromUser.USER_FEATURE_KEY, fromUser.reducer),
    StoreModule.forFeature(
      fromTemplates.TEMPLATES_FEATURE_KEY,
      fromTemplates.reducer
    ),
    EffectsModule.forFeature([UserEffects, TemplatesEffects]),
  ],
  providers: [UserFacade, TemplatesFacade],
})
export class DataAccessModule {
  constructor(
    private readonly transferState: TransferState,
    private readonly store: Store
    ) {
      const isBrowser = this.transferState.hasKey<any>(NGRX_STATE);

      if (isBrowser) {
          this.onBrowser();
      } else {
          this.onServer();
      }
    }
  
    onServer() {

      this.transferState.onSerialize(NGRX_STATE, () => {
          let state;
          this.store.subscribe( ( saveState: any ) => {
              // console.log('Set for browser', JSON.stringify(saveState));
              state = saveState;
          }).unsubscribe();

          return state;
      });
  }

  onBrowser() {
      const state = this.transferState.get<any>(NGRX_STATE, null);
      this.transferState.remove(NGRX_STATE);
      this.store.dispatch( { type: 'SET_ROOT_STATE', payload: state } );
      // console.log('Got state from server', JSON.stringify(state));
  }
}
