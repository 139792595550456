import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import {isPlatformBrowser} from '@angular/common';
import { AuthService } from '@razroo-zeta/data-services';

@Injectable({
  providedIn: 'root'
})
export class AuthRedirectGuard  {
  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: any, 
  private authService: AuthService) {}

  canLoad(): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    if(isPlatformBrowser(this.platformId)) {
      return this.authService.isAuthenticated$.pipe(
        switchMap((isAuthenticated: any) => {
          if (isAuthenticated) {
            this.router.navigate(['/tickets']);
            return of(false);
          }
          else {
            return of(true)
          }
        })
      );
    } else {
      return true;
    }
  }

  canActivate(): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    if(isPlatformBrowser(this.platformId)) {
      return this.authService.isAuthenticated$.pipe(
        switchMap((isAuthenticated: any) => {
          if (isAuthenticated) {
            this.router.navigate(['/home']);
            return of(false);
          }
          else {
            return of(true)
          }
        })
      );
    } else {
      return true;
    }
  }
}