import { NgModule, TransferState, makeStateKey } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import {
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { environment } from '../environments/environment';
import { getMainDefinition } from '@apollo/client/utilities';
import omitDeep from 'omit-deep-lodash';

const STATE_KEY = makeStateKey<any>('apollo.state');
const cleanTypenameLink = new ApolloLink((operation, forward) => {
  // more keys like timestamps could be included here
  const keysToOmit = ['__typename'];

  const def = getMainDefinition(operation.query);
  if (def && (def as any).operation === 'mutation') {
    operation.variables = omitDeep(operation.variables, keysToOmit)
  }
  return forward ? forward(operation) : null
});

const uri = environment.graphQLURI; // <-- add the URL of the GraphQL server here
// https://docs.aws.amazon.com/appsync/latest/devguide/real-time-websocket-client.html

export function createApollo(httpLink: HttpLink, transferState: TransferState) {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8',
    },
  }));

  const cache = new InMemoryCache({
    typePolicies: {
      Template: {
        // how template field is cached
        keyFields: ["orgId", "id", "pathId", "recipeId"],
      },
      Stepper: {
        // note without this caching stepper items of same id were cached
        // this was needed to also specify recipeId should be different
        keyFields: ["id", "pathId", "recipeId"],
      },
      GetTemplateFileUrl: {
        keyFields: ["orgId", "id", "pathId", "recipeId", "fileName"],
      }
    }
  })

  const isBrowser = transferState.hasKey<any>(STATE_KEY);
 
    if (isBrowser) {
      const state = transferState.get<any>(STATE_KEY, null);
      cache.restore(state);
    } else {
      transferState.onSerialize(STATE_KEY, () => {
        return cache.extract();
      });
      // Reset cache after extraction to avoid sharing between requests
      cache.reset();
    }
 

  const link = ApolloLink.from([cleanTypenameLink, basic, httpLink.create({ uri })]);

  return {
    link,
    ssrMode: true,
    cache,
  };
}

@NgModule({
  exports: [HttpClientModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, TransferState],
    }
  ],
})
export class GraphQLModule {}
